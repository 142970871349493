section.text-image-background{
  background-color: #fff;
  padding: 12rem 1em 8rem;
  border-top: 1px solid #a7acaf;
}

@media only screen and (max-width: $media-sm) {
  section.text-image-background{
    padding: 0;

    .image-background{
      padding: 0;
    }
  }
}

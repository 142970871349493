@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap');

//$font-text: 'Neutraface 2 Text Light';
//$font-decorative: 'Neutraface 2 Text Light';

$font-text: 'Neutraface 2 Text light', sans-serif;
$font-decorative: 'Neutraface 2 Text Light';
$font-bold: 'Neutraface 2 Text Bold';

$padding: 1rem;

$default-space: 16px;
$text-color: #222427;

$color-primary: #a5d8c2;
$color-secondary: #009932;
$color-info: #F2F2BC;
$color-text: #222021;
$color-text-light: #363636;
$color-text-very-light: #888;

$color-bg-gray-light: #f2f2f2;
$color-bg-gray: #ebebeb;
$color-bg-gray-dark: #e6e6e6;
$color-bg-primary: #d8ecec;

$color-border-light: #9f9f9f;
$color-border: #e6e6e6;
$color-border-dark: #101010;

$color-dark: #131415;
$color-gray: #b1b1b1;
$color-white: #fff;
$color-red: #fe0101;

$color-error: $color-red;
$color-success: #4BB543;

$box-shadow: 6px 6px 16px rgba(10, 11, 11, 0.24);
$box-shadow-hover: 6px 6px 16px rgba(10, 11, 11, 0.4);

$input-height-base: 4rem;

$link-transition: all .25s ease;
$btn-transition: all .3s ease-in-out;
$default-transition: all .4s ease-in-out;
$slider-transition: all .6s ease-in-out;
$image-transition: all .6s ease-in;

$media-lg: 1600px;
$media-md: 1200px;
$media-sm: 980px;
$media-xs: 767px;
$media-xxs: 480px;

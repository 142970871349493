@media only screen and (min-width: $media-sm) {
  .single-solutions{
    .banner.image{
      min-height: 700px;
    }
  }
}

body.page-template-template-events-archive,
body.page-template-default.page,
body.page-template-template-events-archive{
  .banner.image{
    //min-height: 35vw;
  }
}

.banner.image-only{
  img{
    width: 100%;
    height: auto;
  }
  &:after{
    content: "";
    display: block;
    height: 1rem;
    width: 100%;
    background-color: #9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
}

.section-banner{
  padding: 0;
  position: relative;
  margin-bottom: 6rem;

  .banner.video{
    p{
      max-width: 100%;
    }
  }

  p{
    font-size: 2.2rem;
    line-height: 130%;
    max-width: 80%;
  }

  h1, h2{
    font-weight: normal;
    padding: 0 0 0.8em 0;
    text-align: left;
    margin: 0;
    line-height: 1.2em;
    font-size: 5rem;
    color: black;
    text-transform: uppercase;

    strong{
      font-family: 'Neutraface 2 Text Demi';
    }

    &:after{
      content: "";
      display: block;
      height: 0.3rem;
      width: 6rem;
      margin-top: 2rem;
      background-color: #9deeca;
      background: #97b1ec;
      background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
      background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
      background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);

      height: 0.1rem;
      width: 12rem;
      display: block;
    }
  }

  &:after{
    display: block;
    width: 161px;
    height: 114px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: url('../img/banner-homepage-geometric.png');
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
  }


  h3, h4{
    display: block;
    font-size: 1.75rem;
    line-height: 120%;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 300;
    font-family: $font-text;
  }
}

section.section-banner-page{
  padding: 0;
  margin-top: 60px;

  .col.sm12.sm-middle{
    a{

    }
  }

  .banner.image{
    h1{
      &:after{
        content: "";
        display: block;
        height: 0.2rem;
        width: 9rem;
        margin: 0 auto;
        margin-top: 2rem;
        background-color: #9deeca;
        background: #97b1ec;
        background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
        background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
        background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
      }
    }
  }

  .banner.video{
    video{
      display: block;
    }
    &:after{
      content: "";
      display: block;
      height: 1rem;
      width: 100%;
      background-color: #9deeca;
      background: #97b1ec;
      background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
      background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
      background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    }
  }
}

body.single-portfolio {
  .banner.image{
    padding-bottom: 40%;
  }
}

.banner-image-content{
  margin-bottom: 6rem;
  h2, h3{
    text-align: center;
    color: #fff;
    font-size: 40px;
  }
  h3, h4{
    font-family: $font-text;
  }
   p{
    text-align: center;
    color: #fff;
    font-size: 20px;
  }
}
.banner{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &.image{
    min-height: 20vw;

    &:after{
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1rem;
      width: 100%;
      background-color: #9deeca;
      background: #97b1ec;
      background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
      background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
      background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    }
  }


  &.video{
    position: relative;
    background-size: 0;

    h2, h1{
      display: none;
    }

    video{
      width: 100%;
      height: 56.2vw;
    }
  }

  &:before, &:after{
    content: '';
    display: table;
    clear: both;
    width: 100%;
  }

  &__content{
    margin: 12rem auto;
    padding: 8rem;
    height: 650px;
    max-height: 650px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }


  &__nav {
    list-style: none;
    list-style-type: none;
    margin: 2rem 0 4rem 0;
    padding: 0;

    li {
      display: inline-block;

      &:after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 20px;
        background: #97b1ec;
        background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
        background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
        background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
        margin: 4px 8px;
      }
      &:last-child:after{
        display: none;
      }

      a {
        color: #222427;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: bold;
      }
    }
  }
}


@media only screen and (max-width: $media-sm) {
  .banner{
    .wrapper{
      padding: 0;
    }
    &__content{
      height: auto;
      margin: 0;
      padding: 12rem 8rem;
      max-height: 650px;
    }
    &__nav{
      display: none;
    }

    .btn--black.btn--inverse:not(:hover){
      background-color: white;
    }
  }
}

@media only screen and (min-width: $media-sm) {
  section.section-banner-page{
    .row{
      padding: 200px 20px 0;
    }
  }
}
@media only screen and (max-width: $media-sm) {
  section.section-banner-page{
    .row{
      padding: 20px 20px 0;
    }
  }
}

@media only screen and (max-width: $media-xs) {
  section.section-banner-page{
    margin-top: 50px;
  }
  .section-banner{
    h1{
      font-size: 3.5rem;
      text-align: center;
      &:after{
        display: none;
      }
    }
    p{
      max-width: 100%;
    }
    .buttons{
      .btn:nth-child(2){
        display: none;
      }
    }
  }
  .banner{
    &__content{
      padding: 9rem 2rem;
      text-align: center;
    }
  }
}

section.portfolio{
  padding-bottom: 0;
}

.portfolio-list{
  position: relative;
  &.isLoading{
    padding-bottom: 100px;
    &:before{
      content: "";
      position: absolute;
      bottom: 80px;
      left: 50%;
      width: 20px;
      border: 2px $color-primary solid;
      height: 20px;
      text-align: center;
      border-radius: 10px;
      border-right: 0;
      border-bottom: 0;
      margin-left: -10px;
      margin-top: -10px;
      -webkit-animation:spin 1.5s linear infinite;
      -moz-animation:spin 1.5s linear infinite;
      animation:spin 1.5s linear infinite;
    }
  }
}

@media only screen and (min-width: 0px) {
  .portfolio ul.portfolio-categories {
    list-style:none;
    list-style-type:none;
    margin:0; padding:0;
    text-align:center;
  }
  .portfolio ul.portfolio-categories li {
    display:inline-block;
    margin:0 0.5rem;
  }
  .portfolio ul.portfolio-categories li a {
    text-decoration:underline;
    color:#767676;
    font-family: 'Neutraface 2 Display Medium';
    font-size:18px;
    line-height:100%;
  }
  .portfolio ul.portfolio-categories li a.active {
    color:#94e4cc;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .portfolio ul.portfolio-categories {
    text-align:right;
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .portfolio ul.portfolio-categories li {
    margin: 0 1rem;
  }
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .portfolio .portfolio-item {
    position:relative;
  }

  .portfolio .portfolio-item .image {
    img{
      display: block;
    }
  }

  .portfolio .portfolio-item .copy { position:absolute; bottom:0; left:0; width:100%; }
  .portfolio .portfolio-item .copy h2 { font-size:20px; font-family: 'Neutraface 2 Display Bold'; color:#fff; margin-bottom:0; padding-bottom:0; }
  .portfolio .portfolio-item .copy h2 a { font-size:20px; font-family: 'Neutraface 2 Display Bold'; color:#fff; margin-bottom:0; padding-bottom:0; }
  .portfolio .portfolio-item .copy h3 { font-size:19px;  font-family: 'Neutraface 2 Display Medium'; color:#fff; margin-bottom:0; padding-bottom:0.5rem; }
  .portfolio .portfolio-item .copy h3 p { font-size:19px;  font-family: 'Neutraface 2 Display Medium'; color:#fff; margin-bottom:0; padding-bottom:0; }
  .portfolio .portfolio-item .copy h4 { font-size:12px; font-family: 'Neutraface 2 Text Bold'; color:#9deeca; }
  .portfolio .portfolio-item .copy h4 a { text-decoration:none;}
  .portfolio .portfolio-item .copy h4 a:after { content:", "; }
  .portfolio .portfolio-item .copy h4 a:last-child:after { display:none;}
  .portfolio .portfolio-item .copy h4 a:nth-child(n+2):nth-last-of-type(-n+2):after { display:none; }
  .portfolio .portfolio-item .copy h4 a:nth-child(n+2):nth-last-of-type(-n+1):before { content:"& "; }


  .portfolio .portfolio-item .actions { display:none; }
  .portfolio .portfolio-item .actions a {  }

  .portfolio .btn-load-more {
    background-color:#101010;
    text-transform:uppercase;
    display:block;
    margin:0 auto;
    width:210px;
    font-family: 'Neutraface 2 Text Bold';
    font-size:12px;
    padding:22px;
    padding:8px;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {}

@media only screen and (max-width: 601px) {
 .portfolio-item.mb-2{
   margin-bottom: 0 !important;
 }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .portfolio .portfolio-item .image {
    overflow:hidden;
  }
  .portfolio .portfolio-item .image img {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    display: block;
    width: 100%;
  }
  .portfolio .portfolio-item:hover .image img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .quick-points {}
  .quick-points .item { text-align:center; }
  .quick-points .col:nth-child(1) { background-color:#a8c1f9; }
  .quick-points .col:nth-child(2) { background-color:#a6cbef; }
  .quick-points .col:nth-child(3) { background-color:#a1dddc; }
  .quick-points .col:nth-child(4) { background-color:#9deeca; }
  .quick-points .item h2 {
    color:#fff;
    margin:3rem 0;
  }
  .quick-points .item h2 strong {
    font-family: 'Neutraface 2 Text Bold';
    font-size:70px;
    line-height:70px;
    display:block;
    margin-bottom:1rem;
  }
  .quick-points .item h2 small {
    font-family: 'Neutraface 2 Text Book';
    font-size:20px;
    display:block;
    line-height:20px;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .quick-points .item {
    overflow:hidden;
  }
  .quick-points .item h2 {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .quick-points .item:hover h2 {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .portfolio-copy { background-color:#f4f4f4; }
  .portfolio-copy img {
    width:100%;
    max-width:100%;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .portfolio-obj ul {
    list-style-type:none;
    list-style:none;
    &.sm-left-bar{
      padding-left: 4rem;
    }
  }
  .portfolio-obj ul li {margin-bottom:2rem; }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .portfolio-process { text-align:center; }
  .portfolio-process h2:after {
    content:"";
    display:block;
    height:3px;
    width:40px;
    background-color:#9deeca;
    margin:0 auto;
    margin-top:2rem;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .portfolio-featured-testimonial {
  }
  .portfolio-featured-testimonial {}
  .portfolio-featured-testimonial .item { border:1px solid #f0f0f0; padding:2rem; }
  .portfolio-featured-testimonial .item .circle-stats {
    background-color:#a8c1f9;
    border-radius: 50%;
    width:240px;
    height:240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align:center;
    padding:1rem;
    margin:0 auto;
  }
  .portfolio-featured-testimonial .item .circle-stats h2:before {
    content:"";
    display:block;
    background-image:url('../img/quote-icon-white.png');
    margin:0 auto;
    width:23px;
    height:23px;
  }
  .portfolio-featured-testimonial .item .circle-stats h2 {
    margin-bottom:0; padding-bottom:0;
    color:#fff;
  }
  .portfolio-featured-testimonial .item .circle-stats h2 strong {
    font-family: 'Neutraface 2 Text Bold';
    font-size:50px;
    line-height:100%;
    display:block;
  }
  .portfolio-featured-testimonial .item .circle-stats h2 small {
    font-family: 'Neutraface 2 Display Bold';
    font-size:26px;
    line-height:100%;
    display:block;
  }
  .portfolio-featured-testimonial .item .circle-logo {
    background-color:#9deeca;
    border-radius: 50%;
    width:160px;
    height:160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align:center;
    padding:1rem;
    margin:0 auto;
    margin-top:-3rem;
  }
  .portfolio-featured-testimonial .item .circle-logo img {
    border-radius: 50%;
    width:140px;
    height:140px;
  }
  .portfolio-featured-testimonial .item .quote {position:relative; margin:3rem 0;}
  .portfolio-featured-testimonial .item .quote:before { position:absolute; top:-10px; left:-10px; content:" "; display:block; width:42px; height:42px;
    background-image:url('../img/quote-icon-green.png'); z-index:1; opacity:0.2; }
  .portfolio-featured-testimonial .item .quote q { margin-bottom:3rem; display:block; position:relative; z-index:2;}
  .portfolio-featured-testimonial .item .quote q:before { content:"";}
  .portfolio-featured-testimonial .item .quote q:after { content:"";}
  .portfolio-featured-testimonial .item .quote .author { display:block;}
  .portfolio-featured-testimonial .item .quote .company { display:block;}
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .portfolio-featured-testimonial {}
  .portfolio-featured-testimonial {}
  .portfolio-featured-testimonial .item { position:relative; padding:0; }
  .portfolio-featured-testimonial .item .circle-stats {
    position:absolute;
    top:0;
    left:3rem;
    top:-15%;
    z-index:1;
  }
  .portfolio-featured-testimonial .item .circle-stats h2:before {}
  .portfolio-featured-testimonial .item .circle-stats h2 {}
  .portfolio-featured-testimonial .item .circle-stats h2 strong {}
  .portfolio-featured-testimonial .item .circle-stats h2 small {}
  .portfolio-featured-testimonial .item .circle-logo {
    position:relative;
    z-index:2;
    margin:0;
    margin-top:0;

  }
  .portfolio-featured-testimonial .item .circle-logo img {
  }
  .portfolio-featured-testimonial .item .circle-logo:after {
    font-family:FontAwesome;
    content:"\f178";
    position:absolute;
    top:45%;
    top:calc(50% - 12px);
    right:-28px;
    font-size:30px;
    color:#9deeca;
  }
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .portfolio-results {
    background-color:#222427;
    text-align:center;
    padding:8rem 0;
    position:relative;
  }
  .portfolio-results:before {
    content:"";
    display:block;
    height:6px;
    width:100%;
    background-color:#9deeca;
    margin:0 auto;
    position:absolute;
    top:0;
  }
  .portfolio-results h2 {
    font-family: 'Neutraface 2 Text Demi';
    font-size:30px;
    line-height:100%;
    color:#fff;
  }
  .portfolio-results h2:before {
    position:absolute;
    top:-37px; right:0;
    left:0;
    margin:0 auto;
    content:" "; display:block; width:74px; height:74px;
    background-image:url('/images/bespoke/inginitynation-logo-head.png'); z-index:1;
    background-repeat:no-repeat;
    background-position:center;
  }
  .portfolio-results h2:after {
    content:"";
    display:block;
    height:3px;
    width:40px;
    background-color:#9deeca;
    margin:0 auto;
    margin-top:2rem;
  }
  .portfolio-results ul { list-style:none; list-style-type:none; margin:0; padding:0; text-align:center; }
  .portfolio-results ul li { color:#fff; margin:0; padding:0; margin-bottom:1rem; }
  .portfolio-results ul li strong {}
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

.section-partners {
  padding: 6rem 0;

  li{
    margin: 1rem;

    a{
      max-width: 30rem;
      max-height: 30rem;
      display: block;
      margin: 0 auto;
    }

    img{
      margin: 0 auto;
      display: block;
      max-width: 100%;
      max-height: 30rem;
      width: auto;
      height: auto;
    }
  }
}

@media only screen and (max-width: $media-xs) {
  .section-partners li img{
    max-height: 20rem;
  }
}

$gray: #5d5d5d;
$inputHeight: $input-height-base;
$red: red;
$blue: $color-primary;
$input-font-size: 1.333rem;
$input-border-color: rgba(219, 219, 219, 0.6);

form .wpcf7-not-valid-tip{
  font-size: 1.6rem;
}
.form-block{
  margin-bottom: 4rem;
}
form span.wpcf7-list-item{
  margin: 0;
}

.form-group{
  margin-bottom: $default-space*2;
}
.input-wrapper{

  label {
    display: flex;
    transform:  translate(0, 50%);
    align-items: center;
    font-size: 2rem;
    font-family: Arial;
    color: white;
    font-weight: normal;

    &:before, &:after{
      content: '';
      display: block;
      height: 2px;
      background: $input-border-color;
    }

    &:after {
      flex: 1;
      margin-left: 8px;
    }

    &:before {
      width: 30px;
      margin-right: 8px;
    }

  }

  input[type="text"], input[type="email"], textarea, select{
    border: 2px solid $input-border-color;
    border-top: 0;
    background-color: transparent;
    //box-shadow: 0 8px 20px rgba(182,182,182,0.3);
    color: white;
    font-weight: 500;
    height: $input-height-base;
    line-height: $input-height-base;
    outline: none;
    padding: 0 15px;
    transition: all 0.25s ease;
    font-size: $input-font-size;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
  }

  option {
    color: black;
  }

  textarea{
    height: auto;
    padding: 10px 15px;
    min-height: 17rem;
    line-height: 1.2;
  }
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  padding-right: 4rem;
  background-image: url("../images/common/select.png");
  background-position: right 1rem center;
  background-size: 2rem;
  background-repeat: no-repeat;
}
.effect{
  position: relative;
  display: block;
  margin-bottom: 20px;

  .required{
    color: $red;
  }

  input, textarea, select{
    &.invalid{
      border-color: $red;
    }
  }

  select{
    background-position: right 1rem bottom 30%;
  }

  input, textarea, select{
    background-color: transparent;
    display: block;
    width: 100%;
    height: $inputHeight;
    line-height: $inputHeight;
    color: $blue;
    font-style: normal;
    border-radius: 0;
    outline: none;
    font-weight: 600;
    border: 0;
    border-bottom: 1px #cccccc solid;
    font-size: $input-font-size;
    margin-bottom: 0px;
    padding: 10px 0 0;
    &:-internal-autofill-selected{
      color: $blue !important;
      background-color: transparent !important;
      line-height: 40px !important;
    }

    &.is-invalid{
      border-color: $red;
      & + label + .invalid-feedback{
        display: block;
      }
    }

    & + label{
      position: absolute;
      padding-top: 5px;
      top: 0;
      line-height: $input-height-base;
      left: 0;
      font-size: $input-font-size;
      pointer-events: none;
      transition: all 0.3s ease;
      color: $gray;
      //text-transform: uppercase;
      font-weight: 600;
    }

    &:disabled, &.disabled{
      border-color: #e5e5e5;
    }
    &:focus{
      border-color: $blue;
    }
    &:focus, &.focus{

      &:disabled, &.disabled{
        border-color: #acacac;
        background-color: #fafafa;
        color: #acacac;
      }

      & + label{
        bottom: 100%;
        padding-top: 0;
        font-size: 11px;
        line-height: 11px;
        transform: translate(0, 50%);
      }
    }

  }
  select{
    color: #5d5d5d;
    padding-right: 3rem;

    &:focus, &.focus{
      font-weight: bold;
      color: #5d5d5d;
    }
    &.selected{
      font-weight: bold;
      color: #5d5d5d;
    }
  }

  textarea {
    height: auto;
    overflow: auto;
    max-width: 100%;
    min-width: 100%;
    line-height: 24px;
    padding: 18px 0 8px;
  }
}

.input, .select, .form-control{
  appearance: none;
  border: 1px solid #202729;
  background-color: white;
  //box-shadow: 0 8px 20px rgba(182,182,182,0.3);
  color: #202729;
  font-weight: 500;
  height: $input-height-base;
  line-height: $input-height-base;
  outline: none;
  padding: 0 15px;
  transition: all 0.25s ease;
  font-size: $input-font-size;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;

  //margin-bottom: 3rem;

  &.disabled{
    pointer-events: none;
    background-color: #f5f5f5;
  }
}
.toggle{
  $this: &;
  position: relative;
  display: block;
  cursor: pointer;
  transition: $default-transition;
  margin-bottom: 1rem;

  &:last-of-type{
    margin-bottom: 0;
    border-bottom: 0;
  }

  &:hover{
    #{$this}__title{
      color: $color-secondary
    }
  }

  &.active{
    #{$this}__title{
      color: $color-secondary;
      &:before{
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      &:after{
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }

    }
    #{$this}__content{
      max-height: 9999px;
      opacity: 1;
    }
  }

  &__content{
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: $default-transition;
  }
}
.select-wrapper{
  position: relative;
  display: table;
  margin-bottom: 4rem;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-color: black;
    background-image: url('../images/arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size:2rem;
    display: block;
  }
}

textarea.input, textarea.input--standard{
  height: auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  line-height: 2rem;
  min-height: 9.5rem;
}

textarea.form-control{
  min-height: 150px;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 1.2em;
  display: block;
}


.buttons-row{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .btn{
    margin: 10px;
  }
}

.checkbox-wrapper {
  display: block;
  position: relative;
}
.checkbox-wrapper:after,
.checkbox-wrapper:before {
  clear: both;
  content: "";
  display: block;
  width: 100%;
}
.checkbox-wrapper input[type="checkbox"] {
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  left: -9999px;
  opacity: 0;

  &.required + label:after{
    content: '*';
    color: $color-error;
    font-weight: bold;
  }
}
.checkbox-wrapper input[type="checkbox"]:checked + label::before,
.checkbox-wrapper input[type="checkbox"]:checked + span::before,
label.checked:before {
  font-size: 2.5rem;
}
.checkbox-wrapper label {
  padding-left: 4rem;
  display: block;
  padding-top: 0;
  margin-bottom:2rem;
  font-weight: bold;
  position: relative;
  //text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.checkbox-wrapper.light label{
  font-weight: normal;
  text-transform: none;
}
.wpcf7-list-item-label {
  display: block;
  font-weight: 300;
  font-size: 1.8rem;
}
.checkbox-wrapper input[type="checkbox"] + label::before,
.checkbox-wrapper input[type="checkbox"] + span::before {
  content: '\2713';
  display: block;
  position: absolute;
  text-align: center;
  font-size: 0;
  top: 0.5rem;
  left: 0;
  width: 2rem;
  height: 2rem;
  line-height: 1.8rem;
  border: .1rem solid #202729;
  border-radius: 0;
  background-color: transparent;
  color: black;
  font-weight: normal;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.radio-wrapper {
  display: block;
  position: relative;
  margin-bottom: 1rem;
}
.radio-wrapper:after,
.radio-wrapper:before {
  clear: both;
  content: "";
  display: block;
  width: 100%;
}
.radio-wrapper input[type="radio"] {
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.radio-wrapper input[type="radio"]:checked + label{
  font-weight: bold;
}
.radio-wrapper input[type="radio"]:checked + label::before, label.checked:before {
  background-color: black;
}
.radio-wrapper label {
  padding-left: 4rem;
  display: block;
  font-size: 1.5rem;
  padding-top: 0.2rem;
  font-weight: 300;
  position: relative;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.radio-wrapper label::before {
  content: '\2713';
  display: block;
  position: absolute;
  text-align: center;
  font-size: 0;
  top: 0.5rem;
  left: 0;
  width: 17px;
  height: 17px;
  line-height: 13px;
  border: 2px black solid;
  background-color: transparent;
  color: black;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}


.checkbox-wrapper input[type="checkbox"].validation-error + label:before{
  border-color: $color-error;
}

.validation-error, .form-group.error input{
  border-color: $color-error;
  & + label:before{
    border-color: $color-error;
  }
}

.error-message{
  color: $color-error;
  font-size: 2rem;
  max-height: 0;
  transition: 0.5s ease;
  opacity: 0;
}
.validation-error .error-message,
.validation-error + .error-message,
.validation-error + label + .error-message{
  max-height: 200px;
  opacity: 1;
}

.parsley-errors-list{
  position: absolute;
}

@media only screen and (max-width: $media-lg) {
  .effect input+label, .effect textarea+label, .effect select+label{
    font-size: 2rem;
  }
}

@media only screen and (max-width: $media-xxs) {
  .mailpoet_form{
    flex-direction: column;

    label.mailpoet_hp_email_label+.mailpoet_paragraph,
    .mailpoet_paragraph {
      width: 100%;
      margin-bottom: 20px !important;
    }
  }
  .effect input+label, .effect textarea+label, .effect select+label{
    font-size: 1.6rem;
    white-space: nowrap;
  }
}

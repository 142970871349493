@import "../fonts/fonts";

html {
  scroll-behavior: smooth;
  scroll-padding-top: 20rem;

}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.overflow-hidden {
  overflow-y: hidden !important;
}

.footer{
  padding: 6rem 0;
  background-color: white;

  .title{
    text-transform: uppercase;
    font-size: 1.4rem;
  }

  .numbers-list{
    padding-left: 0;
    list-style: none;
    color: #ccc;

    li{
      position: relative;
      margin-bottom: 20px;

      p{
        padding-left: 3rem;
        color: #555;
      }

      span {
        position: absolute;
        font-size: 1.3rem;
        line-height: 130%;
        color: #ccc;
      }
    }
  }

  .menu {
    li{
      margin: 0;
    }
    a{
      color: black;
      font-weight: bold;
      font-size: 1.3rem;
      line-height: 130%;
      display: block;
    }
  }

  p{
    font-size: 1.3rem;
    margin: 0;
    line-height: 1.3;
    padding: 0;
  }

  form{
    position: relative;
    margin-bottom: 15px;
  }

  .terms-text{
    color: #555;
    font-size: 1.3rem;
    margin: 0;
    line-height: 1.3;

    a{
      color: black;
      font-weight: bold;
      text-decoration-color: black;

      &:hover{
        text-decoration: underline;
      }
    }
  }


  .btn{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4rem;
    padding: 0;
    min-width: 0;

    &:hover:before{
      border-left-color: #fff;
    }

    &:before{
      content: "";
      display: block;
      height: 0;
      width: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 10px solid black;
      position: absolute;
      top: 50%;
      right: 0.8rem;
      transform: translateY(-50%) translateX(-50%);
    }
  }

}

.copy-bar{
  text-align: left;
  background: #f6f6f6;

  .copyright{
    color: #999;
    font-size: 1.4rem;
  }

  .logo-icon{
    background-color: #ebeaea;
    padding: 1rem 0;

    img{
      width: 30px;
      display: block;
      margin: 0 auto;
    }
  }

  .links{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0;

    li{
      margin-left: 2.5rem;
      a{
        color: $color-gray;
        &:hover{
          color: darken($color-gray, 10%);
        }
      }
    }
  }
}


@media only screen and (max-width: $media-sm) {
  .footer .menu li{
    text-align: center;
  }
  .copy-bar{
    background-color: #ebeaea;
    text-align: center;
    .row{
      flex-direction: column;
    }
    .logo-icon{
      a{
        display: block;
        width: 100%;
      }
      img{
        width: 16.66vw;
      }
    }
    .col-md-5{
      order: 2;
    }
    .col-md-2{
      order: 1;
    }
    .links{
      justify-content: center;
      align-items: center;
    }
  }

  .footer{
    .col-lg-6.col-md-6 {
      margin-bottom: 1.5rem;
      p, li, ul{
        margin-bottom: 1.5rem;
      }
    }
  }
}

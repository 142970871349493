/*//////////////////////////////////////////////////////////////////////////////////////////////////////////

    _blogs.css

    Version: 1.0.0
    CreatedOn: 23/05/17
    CreatedBy: lukas@webnetism.com
    UpdatedOn: 23/05/17

//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .single-blog-content{
    img.aligncenter{
      margin: 10px auto !important;
      display: block;
      height: auto !important;
    }
    ul, ol{
      list-style: initial;
      padding: 0 0 40px 40px;
      margin: initial;
      &.sm-left-bar{
        list-style: none;
        li{
          list-style: none;
        }
      }
      li{
        list-style: initial;
      }
    }
  }
  .blogs ul.blog-categories {
    list-style:none;
    list-style-type:none;
    margin:0; padding:0;
    text-align:center;
  }
  .blogs ul.blog-categories li {
    display:inline-block;
    margin:0 1rem;
  }
  .blogs ul.blog-categories li a {
    text-decoration:underline;
    color:#767676;
    font-family: 'Neutraface 2 Display Medium';
    font-size:18px;
    line-height:100%;
  }
  .blogs ul.blog-categories li a.active {
    color:#94e4cc;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .blogs ul.blog-categories {
    text-align:right;
  }

}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .blogs ul.blog-categories li {
    margin:0 1rem;
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .author-item { position:relative; }
  .author-item:after {
    content:"";
    display:block;
    height:0.3rem;
    width:100%;
    margin:0 auto;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);

    position:absolute;
    bottom:0;
  }


  .author-item .image {
  }
  .author-item .image a {
    display:block;
  }
  .author-item .image a img {
    display:block;
    width:100%;
    max-width:220px;
    border-radius:50%;
    margin:0 auto;
    padding:1rem;
  }
  .author-item .copy {}
  .author-item .copy span {}
  .author-item .copy span:after {
    content:",";
    padding-right:1rem;
  }
  .author-item .copy span:last-of-type:after {
    display:none;
  }
  .author-item .copy span.author-name {}
  .author-item .copy span.author-role {}
  .author-item .copy span.blog-release-date {}

}


/*// Medium and Up */

@media only screen and (min-width: 601px) {

}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .author-item:before {
    content:"";
    display:block;
    height:0.3rem;
    width:100%;
    margin:0 auto;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);

    position:absolute;
    top:0;
  }

  .author-item .image a img  {
    padding:0;
  }
  .author-item .copy { margin-left:1rem; }
  .author-item .copy span {display:block;}



  .author-item .image a {
    overflow:hidden;
    border-radius:50%;
  }
  .author-item .image a img {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .author-item .image:hover a img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .share-on ul {
    list-style:none; list-style-type:none;
    display:block;
    margin:0; padding:0;
    text-align:center;
  }
  .share-on ul li {
    display:inline-block;
  }
  .share-on ul li a {
    text-decoration:none;
    display:block;
    text-align:center;
    border-radius:50%;
    padding:0.5rem;
    width:2.8rem;
    line-height: 1.6rem;
    height: 2.8rem;
  }
  .share-on ul li a.socialshare-linkedin  {
    background-color:#0077B5; color:#fff;
  }
  .share-on ul li a.socialshare-twitter {
  }
  .share-on ul li:nth-child(4) a {
    background-color:transparent;
    text-decoration: underline;
    color: #767676;
    font-family: 'Neutraface 2 Display Medium';
  }
  .share-on ul li a span > span {
    display:none;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .share-on ul {
    text-align:left;
  }
  .share-on ul li a:hover {
    opacity:0.8;
  }

  .share-on ul li a {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .share-on ul li a:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {

  .blogs .blog-item {}
  .blogs .blog-item h2 {}
  .blogs .blog-item h3 {}

  .blogs .blog-item {
    position:relative;
    margin-bottom:4rem;
  }

  .blogs .blog-item .image {}
  .blogs .blog-item .image img {
    max-width:100%;
    display:block;
    margin:0 auto;
  }

  .blogs .blog-item .copy {
    margin-top:2rem;
  }
  .blogs .blog-item .copy h2 {
    font-size:22px; font-family: 'Neutraface 2 Display Medium';
    color:#1e1e1e;
    margin-bottom:0; padding-bottom:0;
    overflow:hidden;
  }
  .blogs .blog-item .copy h2 a {
    font-size:22px;
    font-family: 'Neutraface 2 Display Medium';
    color:#1e1e1e;
    margin-bottom:0; padding-bottom:0; }

  .blogs .blog-item .copy h4 {
    font-size:13px; font-family: 'Neutraface 2 Text Bold';
    color:#fff;
    background-color:rgba(157, 238, 202, 0.6);
    text-transform:uppercase;

    position:absolute;
    top:3rem;
    left:3rem;

    padding:1rem;
    text-align:center;
    min-width:80px;
  }
  .blogs .blog-item .copy h4 a { text-decoration:none; color:#fff; display:block; }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .blogs .blog-item {
    margin-bottom:0;
  }
  .blogs .blog-item .copy h2 {
    height:147px;
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .blogs .blog-item .image {
    overflow:hidden;
  }
  .blogs .blog-item .image img {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .blogs .blog-item .image:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 600px) {
  .single-blog-content table {
    font-size: 1.5rem;
  }
  .sm2-mb{
    img{
      float: none !important;
      margin: 10px auto !important;
      height: auto !important;
    }
  }
}

section.testimonial-block{
  padding: 8rem 0;
  background-color: #f7f7f5;

  &.transparent{
    background-color: transparent;

    .testimonial{
      border: 1px solid #f0f0f0;
    }
  }

  .circle-text{
    background-color: #a8c1f9;
    border-radius: 50%;
    width: 240px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    margin: 0 auto;
    color: white;
    position: absolute;
    top: 0;
    left: 3rem;
    top: -50%;
    z-index: 1;

    img{
      width: 23px;
      display: block;
      margin: 0 auto;
    }

    h3{
      font-family: 'Neutraface 2 Text Bold';
      font-size: 50px;
      line-height: 100%;
      display: block;
      margin: 0;
      padding: 0;
    }

    p{
      font-family: 'Neutraface 2 Display Bold';
      font-size: 26px;
      margin: 0;
      padding: 0;
      line-height: 100%;
      display: block;
    }
  }

  .circle-logo{
    background-color: #9deeca;
    border-radius: 50%;
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    margin: 0 auto;
    margin-top: -3rem;
    overflow: hidden;
    position: relative;
    z-index: 2;
    margin: 0;
    margin-top: 0;

    img{
      -moz-transition: all 0.8s;
      -webkit-transition: all 0.8s;
      transition: all 0.8s;
      border-radius: 50%;
      width: 140px;
      height: 140px;
    }

    &:hover img{
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .author{
    display: block;
  }

  .testimonial{
    background-color: white;
  }

  .quote{
      position: relative;
      margin: 3rem 0;
      padding-right: 2rem;

    &:before{
      position: absolute;
      top: -10px;
      left: -10px;
      content: " ";
      display: block;
      width: 42px;
      height: 42px;
      background-image: url('../img/quote-icon-green.png');
      z-index: 1;
      opacity: 0.2;
    }
  }
}

@media only screen and (max-width: $media-sm) {
  section.testimonial-block {
    .circle-text{
      position: static;
      margin-top: 3rem;
      padding-bottom: 3rem;
    }
    .circle-logo{
      margin: -3rem auto 0;
    }

    .quote{
      padding-left: 2rem;
    }
  }
  section.testimonial-block .quote:before{
    left: 10px;
  }
}

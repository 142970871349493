section.section-text-image{
  background-color: #f4f4f4;
  padding: 0;

  .video-wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
  }

  img{
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    width: 100%;
  }

  ul{
    list-style: initial;
    padding: 0 0 0 40px;
    margin: initial;
    li{
      list-style: initial;
    }
  }
  ol {
    list-style: initial;
    padding: 0 0 20px 40px;
    margin: initial;

    li {
      list-style: auto;
      margin-bottom: 20px;
    }
  }
}
@media only screen and (min-width: 993px) {
  section.section-text-image.image--left > .row > .col{
    &:first-child{
      order: 2;
    }
    &:last-child{
      order: 1;
    }
  }
}
@media screen and (max-width: $media-sm) { // 980px;
  section.section-text-image{
    .video-wrapper{
      padding-top: 0;
    }
  }
}

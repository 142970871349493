/*//////////////////////////////////////////////////////////////////////////////////////////////////////////

    _header.css

    Version: 1.0.0
    CreatedOn: 18/05/17
    CreatedBy: lukas@webnetism.com
    UpdatedOn: 25/05/17

//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */
@media only screen and (min-width: 0px) {

  .header {
    position:fixed;
    top:0;
    right:0;
    left:0;
    width:100%;
    margin:0 auto;
    background-color:transparent;
    transition:background 0.5s, padding 0.5s;
    padding:1rem;
    z-index:100;

    &__row{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__navbar{
      &__menu{
        ul{
          display: flex;
          font-size: 1.5rem;
          align-items: center;

          li{
            margin-left: 3rem;
          }

          a{
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  /*sticky*/
  .header.sticky {
    position:fixed;
    top:0;
    right:0;
    left:0;
    width:100%;
    margin:0 auto;
    /*background-color:#1a1a1a;*/
    background-color:#222721;
    z-index:999;
    color: white;

   a.navbar-trigger strong span{
     background-color: white;
   }

    a{
      color: white;
    }

    svg path.st0{
      fill: white;
    }
  }
  .header.sticky:after {
    content:"";
    display:block;
    position:absolute;
  }
  /*eof:sticky*/
}

/*// Medium and Up */
@media only screen and (min-width: 601px) {
  /*sticky*/
  .header {
    padding:3rem 4rem 4rem 4rem;
  }
  .header.sticky {
    padding:1rem 4rem;
  }
  /*eof:sticky*/
}

/*// Large and Up */
@media only screen and (min-width: 993px) {
  .header.sticky {
    padding:1rem 4rem;
  }
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */
@media only screen and (min-width: 0px) {
  .header__logo {}
  .header__logo a {}
  .header__logo a img,
  .header__logo a svg {
    display:block;
    width:100%;
    max-width:184px;
    max-height:30px;
  }
  .header__logo svg path,
  .header__logo svg .st0 { fill:#222721; }
  .header__logo svg .st1 { fill:#ffffff; }

  /*sticky*/
  .header.sticky .logo svg path,
  .header.sticky .logo svg .st0 { fill:#ffffff; }
  .header.sticky .logo svg .st1 { fill:#222721; }
  /*eof:sticky*/
}

/*// Medium and Up */
@media only screen and (min-width: 601px) {
}

/*// Large and Up */
@media only screen and (min-width: 993px) {
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */
@media only screen and (min-width: 0px) {
  .header__navbar {
  }
  .header__navbar ul {
    list-style:none;
    list-style-type:none;
    margin:0; padding:0;
    text-align:right;
  }
  .header__navbar ul li {
    display:inline-block;
    margin-left:1rem;
    margin-right: 1rem;
    vertical-align: middle;
  }
  .header__navbar ul li a {
    font-family: 'Neutraface 2 Text Book';
    font-size:15px;
    color:#1a1a1a;
    text-decoration:none;
    .fa{
      display: block;
    }
  }
  .header__navbar ul li a span {}
  .header__navbar ul li a strong {
    font-family: 'Neutraface 2 Text Bold';
    position:relative;
  }
  /*.header__navbar ul li a strong:after {
      content:"";
      display:block;
      height:1px;
      width:100%;
      background-color:#767676;
      position:absolute;
      bottom:0;
      right:0;
      left:0;
  }*/

  /*trigger*/
  .header a.navbar-trigger {
    display:inline-block;
    vertical-align:middle;
  }
  .header a.navbar-trigger span {
    margin-right:0.5rem;
  }
  .header a.navbar-trigger strong {
    display:inline-block;
    vertical-align:middle;
  }
  .header a.navbar-trigger strong:after {
    display:none;
  }

  .header a.navbar-trigger strong span {
    display:block;
    width:30px;
    height:3px;
    background-color:#1a1a1a;
  }
  .header a.navbar-trigger strong span:nth-child(1) {
  }
  .header a.navbar-trigger strong span:nth-child(2) {
    margin:3px 0;
  }
  .header a.navbar-trigger strong span:nth-child(3) {
  }
  /*eof:trigger*/

  /*hide and show*/
  .header__navbar ul li:nth-child(1) { display:none; text-transform:uppercase; }
  .header__navbar ul li:nth-child(1) a:nth-child(1) {}

  .header__navbar ul li:nth-child(2) { }
  .header__navbar ul li:nth-child(2) a:nth-child(1) { }
  .header__navbar ul li:nth-child(2) a:nth-child(2) { display:none; }

  .header__navbar ul li:nth-child(3) {}
  .header__navbar ul li:nth-child(3) a:nth-child(1) { }
  .header__navbar ul li:nth-child(3) a:nth-child(2) { display:none;}

  .header__navbar ul li:nth-child(4) { }
  .header__navbar ul li:nth-child(4) a:nth-child(1) { display:none;}
  .header__navbar ul li:nth-child(4) a:nth-child(2) { }
  /*eof:hide and show*/

  /*sticky*/
  .header.sticky .navbar ul li a {
    color:#fff;
  }
  .header.sticky .navbar ul li a strong:after {
    background-color:#fff;
  }
  .header.sticky .navbar ul li a.navbar-trigger strong span {
    background-color:#fff;
  }
  /*eof:sticky*/

}

/*// Medium and Up */
@media only screen and (min-width: 601px) {

  .header__navbar ul li {
    margin-left:2rem;
  }

  /*hide and show*/
  .header__navbar ul li:nth-child(1) { display:inline-block; }
  .header__navbar ul li:nth-child(1) a:nth-child(1) {}

  .header__navbar ul li:nth-child(2) { }
  .header__navbar ul li:nth-child(2) a:nth-child(1) { }
  .header__navbar ul li:nth-child(2) a:nth-child(2) { }

  .header__navbar ul li:nth-child(3) {}
  .header__navbar ul li:nth-child(3) a:nth-child(1) { }
  .header__navbar ul li:nth-child(3) a:nth-child(2) { }

  .header__navbar ul li:nth-child(4) { }
  .header__navbar ul li:nth-child(4) a:nth-child(1) { display:inline-block; }
  .header__navbar ul li:nth-child(4) a:nth-child(2) { }
  /*eof:hide and show*/

}

/*// Large and Up */
@media only screen and (min-width: 993px) {
  .header__navbar ul li {
    margin-left:3rem;
  }

  /*trigger*/
  .header a.navbar-trigger strong span {
    transition: all 0.5s;
  }
  .header a.navbar-trigger strong span:nth-child(1) {
    width:30px;
  }
  .header a.navbar-trigger strong span:nth-child(2) {
    width:24px;
    margin-left:6px;
  }
  .header a.navbar-trigger strong span:nth-child(3) {
    width:20px;
    margin-left:10px;
  }

  .header__navbar ul li:hover a.navbar-trigger strong span:nth-child(1) {
    width:30px;
  }
  .header__navbar ul li:hover a.navbar-trigger strong span:nth-child(2) {
    width:30px;
    margin-left:0;
  }
  .header__navbar ul li:hover a.navbar-trigger strong span:nth-child(3) {
    width:30px;
    margin-left:0;
  }
  /*eof:trigger*/

  /*hide and show*/
  .header__navbar ul li:nth-child(1) {  }
  .header__navbar ul li:nth-child(1) a:nth-child(1) {}

  .header__navbar ul li:nth-child(2) { }
  .header__navbar ul li:nth-child(2) a:nth-child(1) { display:none; }
  .header__navbar ul li:nth-child(2) a:nth-child(2) { display:inline-block; }

  .header__navbar ul li:nth-child(3) {}
  .header__navbar ul li:nth-child(3) a:nth-child(1) { display:none; }
  .header__navbar ul li:nth-child(3) a:nth-child(2) { display:inline-block; }

  .header__navbar ul li:nth-child(4) { }
  .header__navbar ul li:nth-child(4) a:nth-child(1) {}
  .header__navbar ul li:nth-child(4) a:nth-child(2) { }
  /*eof:hide and show*/
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */
@media only screen and (min-width: 0px) {
  .side-nav {
    display:block;

    position:fixed;
    top:0;
    right:0;
    height:100%;
    max-height:100%;
    width:250px;
    margin-right:-250px;
    background-color:#fff;
    transition:all 0.5s;
    padding:1rem;
    overflow:auto;
    z-index: 9999;
  }
  .side-nav ul {
    list-style:none;
    list-style-type:none;
    margin:0; padding:0;
    text-align:right;
    width:auto;
  }
  .side-nav ul li {
    ul{
      margin-top: -1rem;

      li a{
        padding: 0 6.5rem 0 0;
        font-size: 75%;
      }
    }
  }
  .side-nav ul li a {
    font-family: 'Neutraface 2 Text Book';
    font-size:15px;
    color:#1a1a1a;
    text-decoration:none;
    display:block;
    text-transform: uppercase;
    padding: 1rem 3rem 1rem 1rem;
  }

  .side-nav ul li.LS-menu {
    display:block;
    width:auto;
    text-transform:uppercase;
    margin-right:2rem;
  }
  .side-nav ul li.LS-menu ul {
    margin-top:-1rem;
    margin-bottom:1rem;
  }
  .side-nav ul li.LS-menu li a {
    padding:0 6.5rem 0 0.25rem;
    font-size:75%;
  }
  .side-nav ul li.LS-menu a i{
    padding-left:20px;
    font-size:130%;
  }

  .side-nav ul li.LS-menu ul ul{
    margin-top:0;
    margin-bottom:1rem;
  }

  /*logo*/
  .side-nav > ul > li:nth-child(1) {
  }
  .side-nav > ul > li:nth-child(1) > a {
    /*content:url('/uploads/menu/menu-logo.png');*/
    display:block;
    margin:0 auto;
    max-width:100%;
    max-width:200px;
    background-image:url('/wp-content/uploads/menu/menu-logo.png');
    background-size:100%;
    background-repeat:no-repeat;
    height:100%;
    max-height:137px;
  }
  .side-nav > ul > li:nth-child(1) > a em {
    visibility: hidden;
  }

  .side-nav > ul > li:nth-child(1) > span.close {
    position:absolute;
    top:2rem;
    right:2rem;
    height:20px;
    width:20px;
    background-color:#fff;
    display:block;
    text-align:center;
    font-size:18px;
    line-height:20px;
    cursor:pointer;
  }
  .side-nav > ul > li:nth-child(1):after {
    content:"";
    display:block;
    height:0.3rem;
    width:6rem;
    margin:0 auto;
    margin-top: 1rem;
    margin-bottom:1rem;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);

  }
  /*eof:logo*/

  /*cms li*/
  //.side-nav > ul > li:nth-child(n+2):nth-child(-n+7) > a:after {
  //  display:inline-block;
  //  font-family:"Ionicons";
  //  /*content:"\f15b";*/
  //  width:15px;
  //  margin-left:2rem;
  //  text-align:center;
  //  font-size:2rem;
  //  vertical-align: middle;
  //}
  /*.side-nav > ul > li:nth-child(2) > a:after {
  content:"\f447";
}
.side-nav > ul > li:nth-child(3) > a:after {
  content:"\f341";
}
.side-nav > ul > li:nth-child(4) > a:after {
  content:"\f3ef";
}
.side-nav > ul > li:nth-child(5) > a:after {
  content:"\f43e";
}
.side-nav > ul > li:nth-child(6) > a:after {
  content:"\f44e";
}
.side-nav > ul > li:nth-child(7) > a:after {
  content:"\f4b8";
}
  */
  /*eof:cms li*/

  /*tel*/
  .side-nav > ul > li > a:after{
    display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-left: 20px;
    font-size: 130%;
  }
  .side-nav > ul > li:nth-child(2) > a:after{
    content: "\f447";
  }
  .side-nav > ul > li:nth-child(3) > a:after{
    content: "\f341";
  }
  .side-nav > ul > li:nth-child(4) > a:after{
    content: "\f3ef";
  }
  .side-nav > ul > li:nth-child(5) > a:after{
    content: "\f43e";
  }
  .side-nav > ul > li:nth-child(6) > a:after{
    content: "\f44e";
  }
  .side-nav > ul > li:nth-child(7) > a:after{
    content: "\f4b8";
  }
  .side-nav > ul > li:nth-child(8) > a:after{
    content: "\f117";
  }
  .side-nav > ul > li:nth-child(9) > a:after{
    content: "\f4b8";
  }
  .side-nav > ul > li:nth-last-child(3) {
    text-align:left;
  }
  .side-nav > ul > li:nth-last-child(3):before {
    content:"";
    display:block;
    height:0.3rem;
    width:6rem;
    margin:0 auto;
    margin-top: 1rem;
    margin-bottom:1rem;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .side-nav > ul > li:nth-last-child(3) a {
    padding:0.5rem 1rem;
  }
  .side-nav > ul > li:nth-last-child(3) a:before {
    display:inline-block;
    font-family:"Ionicons";
    content:"\f4b9";
    width:15px;
    margin-right:2rem;
    text-align:center;
    font-size:2rem;
    vertical-align: middle;
  }
  /*eof:tel*/

  /*email*/
  .side-nav > ul > li:nth-last-child(2) {
    text-align:left;
  }
  .side-nav > ul > li:nth-last-child(2) a {
    padding:0.5rem 1rem;
    text-transform: none;
  }
  .side-nav > ul > li:nth-last-child(2) a:before {
    display:inline-block;
    font-family:"Ionicons";
    content:"\f3da";
    width:15px;
    margin-right:2rem;
    text-align:center;
    font-size:2rem;
    vertical-align: middle;
  }
  /*eof:email*/

  /*award*/
  .side-nav > ul > li:nth-last-child(1) a {
    /*content:url('/uploads/menu/menu-footer.png');*/
    max-width:75%;
    display:block;
    margin:0 auto;
    font-size: 0;
    background-image:url("../img/menu-footer.png");
    background-size:100%;
    background-repeat:no-repeat;
    height:200px;
    max-height:200px;
  }
  .side-nav > ul > li:nth-last-child(1) a em {
    visibility:hidden;
  }
  /*eof:award*/

  /*toggle-sub*/
  .side-nav ul li a .icon:after {
    display:inline-block;
    font-family:FontAwesome;
    content:"\f067";
    width:15px;
    margin-right:2rem;
    text-align:center;
    font-size:12px;
  }
  .side-nav ul li a .icon.active:after {
    content:"\f068";
  }


  /*eof:toggle-sub*/
  .side-nav ul li ul {
    display:none;
  }
  .side-nav ul li ul.active {
    display:block;
  }

  /*active*/
  .side-nav.active {
    margin-right:0;
  }
  /*eof:active*/

}

/*// Medium and Up */
@media only screen and (min-width: 601px) {

  /*logo*/
  .side-nav > ul > li:nth-child(1):after {
    margin-top: 2rem;
    margin-bottom:2rem;

  }
  /*eof:logo*/

  /*tel*/
  .side-nav > ul > li:nth-last-child(3):before {
    margin-top: 2rem;
    margin-bottom:2rem;
  }
  /*eof:tel*/

}

/*// Large and Up */
@media only screen and (min-width: 993px) {

  .side-nav {
    width: 340px;
    margin-right: -340px;
    padding: 50px;
  }
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

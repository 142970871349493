/*//////////////////////////////////////////////////////////////////////////////////////////////////////////

    _featured.css

    Version: 1.0.0
    CreatedOn: 30/05/17
    CreatedBy: lukas@webnetism.com
    UpdatedOn: 30/05/17

//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Featured Gallery*/

}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Featured News*/
  .featured-news {}
  .featured-news h2,
  .featured-news h2 a {
    /*font-family: 'Neutraface 2 Text Light';
    font-size:27px;*/
    color:#323232;
  }
  .featured-news .item h2 {}
  .featured-news .item h3 {}
  .featured-news .item {
    position:relative;
  }
  .featured-news .item .image {}
  .featured-news .item .image img {
    width:100%;
    display:block;
    margin:0 auto;
  }
  .featured-news .item .copy {
    margin-top:2rem;
  }
  .featured-news .item .copy h2 {
    font-size:22px; font-family: 'Neutraface 2 Display Medium';
    color:#1e1e1e;
    margin-bottom:0; padding-bottom:0;
    height:147px;
    overflow:hidden;
  }
  .featured-news .item .copy h2 a {
    font-size:22px;
    font-family: 'Neutraface 2 Text Demi';
    color:#1e1e1e;
    margin-bottom:0; padding-bottom:0; }

  .featured-news .item .copy h4 {
    font-size:13px; font-family: 'Neutraface 2 Text Bold';
    color:#fff;
    background-color:rgba(157, 238, 202, 0.6);
    text-transform:uppercase;
    position:absolute;
    top:3rem;
    left:3rem;
    padding:1rem;
    text-align:center;
    min-width:80px;
  }
  .featured-news .item .copy h4 a {
    text-decoration:none;
    color:#fff;
    display:block;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .featured-news .item .image {
    overflow:hidden;
  }
  .featured-news .item .image img {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .featured-news .item .image:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Featured Pages*/
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Featured Portfolio*/
  .featured-portfolio .item {
    position:relative;
  }
  .featured-portfolio .item .image {}
  .featured-portfolio .item .image img {
    width:100%;
    display:block;
  }
  .featured-portfolio .item .copy { position:absolute; bottom:0; left:0; width:100%; }
  .featured-portfolio .item .copy h2 { font-size:20px; font-family: 'Neutraface 2 Display Bold'; color:#fff; margin-bottom:0; padding-bottom:0; }
  .featured-portfolio .item .copy h2 a { font-size:20px; font-family: 'Neutraface 2 Display Bold'; color:#fff; margin-bottom:0; padding-bottom:0; }
  .featured-portfolio .item .copy h3 { font-size:19px;  font-family: 'Neutraface 2 Display Medium'; color:#fff; margin-bottom:0; padding-bottom:0.5rem; }
  .featured-portfolio .item .copy h3 p { font-size:19px;  font-family: 'Neutraface 2 Display Medium'; color:#fff; margin-bottom:0; padding-bottom:0; }
  .featured-portfolio .item .copy h4 { font-size:12px; font-family: 'Neutraface 2 Text Bold'; color:#9deeca; }
  .featured-portfolio .item .copy h4 a { text-decoration:none;}
  .featured-portfolio .item .copy h4 a:after { content:", "; }
  .featured-portfolio .item .copy h4 a:last-child:after { display:none;}
  .featured-portfolio .item .copy h4 a:nth-child(n+2):nth-last-of-type(-n+2):after { display:none; }
  .featured-portfolio .item .copy h4 a:nth-child(n+2):nth-last-of-type(-n+1):before { content:"& "; }
  .featured-portfolio .item .actions { display:none; }
  .featured-portfolio .item .actions a {  }
  .featured-portfolio .btn-load-more {
    background-color:#101010;
    text-transform:uppercase;
    display:block;
    margin:0 auto;
    width:210px;
    font-family: 'Neutraface 2 Text Bold';
    font-size:12px;
    padding:22px;
    padding:8px;
  }

}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .featured-portfolio .item .image {
    overflow:hidden;
  }
  .featured-portfolio .item .image img {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .featured-portfolio .item:hover .image img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {

  /*NOTE: Featured Team inherits from Team.css*/

  /*Featured Team*/
  .featured-team {
    background-color:#151517;
    background-image:url(/wp-content/uploads/team/our-team.jpg);
    background-position:top center;
    background-repeat:no-repeat;
    background-size:100%;
    color:#fff;
    margin-top:0;
    padding:1rem;
  }
  .featured-team .featured-team-banner .col {
    height:auto;
  }
  .featured-team .featured-team-banner .team-item {
    margin:1rem;
  }
  .featured-team .slick-dots li button:before {
    color:#fff;
  }
  .featured-team .slick-dots li.slick-active button:before {
    color:#9deeca;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Featured Testimonial*/
  .pc-about-us .featured-testimonials {
    background-color:#f7f7f5;
  }
  .featured-testimonials {
    background-color:transparent;
  }
  .featured-testimonials .item { border:1px solid #f0f0f0; padding:2rem; background-color:#fff;}
  .featured-testimonials .item .circle-stats {
    background-color:#a8c1f9;
    border-radius: 50%;
    width:240px;
    height:240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align:center;
    padding:1rem;
    margin:0 auto;
  }
  .featured-testimonials .item .circle-stats h2:before {
    content:"";
    display:block;
    background-image:url('../img/quote-icon-white.png');
    margin:0 auto;
    width:23px;
    height:23px;
  }
  .featured-testimonials .item .circle-stats h2 {
    margin-bottom:0; padding-bottom:0;
    color:#fff;
  }
  .featured-testimonials .item .circle-stats h2 strong {
    font-family: 'Neutraface 2 Text Bold';
    font-size:50px;
    line-height:100%;
    display:block;
  }
  .featured-testimonials .item .circle-stats h2 small {
    font-family: 'Neutraface 2 Display Bold';
    font-size:26px;
    line-height:100%;
    display:block;
  }
  .featured-testimonials .item .circle-logo {
    background-color:#9deeca;
    border-radius: 50%;
    width:160px;
    height:160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align:center;
    padding:1rem;
    margin:0 auto;
    margin-top:-3rem;
  }
  .featured-testimonials .item .circle-logo img {
    border-radius: 50%;
    width:140px;
    height:140px;
  }
  .featured-testimonials .item .quote {position:relative; margin:3rem 0; padding-right:2rem;}
  .featured-testimonials .item .quote:before { position:absolute; top:-10px; left:-10px; content:" "; display:block; width:42px; height:42px;
    background-image:url('../img/quote-icon-green.png'); z-index:1; opacity:0.2; }
  .featured-testimonials .item .quote q { margin-bottom:3rem; display:block; position:relative; z-index:2;}
  .featured-testimonials .item .quote q:before { content:"";}
  .featured-testimonials .item .quote q:after { content:"";}
  .featured-testimonials .item .quote .author { display:block;}
  .featured-testimonials .item .quote .company { display:block;}
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .featured-testimonials {}
  .featured-testimonials {}
  .featured-testimonials .item { position:relative; padding:0; }
  .featured-testimonials .item .circle-stats {
    position:absolute;
    top:0;
    left:3rem;
    top:-15%;
    z-index:1;
  }
  .featured-testimonials .item .circle-stats h2:before {}
  .featured-testimonials .item .circle-stats h2 {}
  .featured-testimonials .item .circle-stats h2 strong {}
  .featured-testimonials .item .circle-stats h2 small {}
  .featured-testimonials .item .circle-logo {
    position:relative;
    z-index:2;
    margin:0;
    margin-top:0;

  }
  .featured-testimonials .item .circle-logo img {
  }
  .featured-testimonials .item .circle-logo:after {
    font-family:FontAwesome;
    content:"\f178";
    position:absolute;
    top:45%;
    top:calc(50% - 12px);
    right:-28px;
    font-size:30px;
    color:#9deeca;
  }
  .featured-testimonials .item .circle-logo {
    overflow:hidden;
  }
  .featured-testimonials .item .circle-logo img {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .featured-testimonials .item .circle-logo:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Featured Links*/
  .featured-links {
  }
  .featured-links:after {
    content:"";
    display:block;
    height:400px;
    width:100%;
    background-color:#f4f4f2;
    margin-top:-400px;
    border-top:1px solid #a7acaf;
    pointer-events:none;
  }

  .featured-links h2,
  .featured-links h2 a {
    font-family: 'Neutraface 2 Text Light';
    font-size:27px;
    color:#323232;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////

    _team.css

    Version: 1.0.0
    CreatedOn: 23/05/17
    CreatedBy: lukas@webnetism.com
    UpdatedOn: 23/05/17

//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .team {
    margin-top:0;
  }
  .team .team-item { position:relative; z-index:1; }
  .team .team-item .image {}
  .team .team-item .image a {}
  .team .team-item .image a img {
    display:block;
    max-width:100%;
    margin:0 auto;
  }
  .team .team-item .image {
    position:relative;
  }
  .team .team-item .image:after {
    content:"";
    display:block;
    background-color:rgba(23, 23, 24, 0.30);
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    pointer-events: none;
  }
  .team .team-item .copy {
    position:absolute;
    z-index:2;
    bottom:0;
    left:0;
    text-align:left;
  }
  .team .team-item .copy h2,
  .team .team-item .copy h2 a {
    margin:0; padding:0;
    font-family: 'Neutraface 2 Text Bold';
    font-size:18.5px;
    color:#fff;
  }
  .team .team-item .copy h3,
  .team .team-item .copy h3 a {
    margin:0; padding:0;
    font-family: 'Neutraface 2 Text Book';
    font-size:15.85px;
    color:#9deeca;
  }
  .team .team-item .copy h3:after {
    margin-top:1rem;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .team {
    margin-top:-13rem;
  }
}

/*// Large and Up */

@media only screen and (min-width: 993px) {
  .team {
    margin-top:-30rem;
  }
  .team .team-item .copy {
    display:none;
  }
  .team .team-item .image:after {
    display:none;
  }
  .team .team-item:hover .image:after {
    display:block;
  }
  .team .team-item:hover .copy {
    display:block;
  }
  .team .team-item .image {
    overflow:hidden;
  }
  .team .team-item .image a img {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .team .team-item:hover .image a img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .team-item-page {
  }
  .team-item-page .container {
    margin-top:8rem;
  }

  .team-item-page .image {
    width:100%;
    overflow:hidden;
    background: rgb(227,224,219); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(227,224,219,1) 0%, rgba(252,251,248,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(227,224,219,1) 0%,rgba(252,251,248,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(227,224,219,1) 0%,rgba(252,251,248,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e3e0db', endColorstr='#fcfbf8',GradientType=0 ); /* IE6-9 */
  }

  .team-item-page .image img {
    margin:0 auto;
    display:block;
    /*min-height:100%;*/
    min-width:100%;
    max-width: 100%;
  }
  .team-item-page .copy {
    color:#fff;
    position:relative;
    z-index:1;
    padding:4rem 2rem;
  }
  .team-item-page .copy:after {
    content:"";
    display:block;
    background-color:#181818;
    height:100%;
    width:100%;
    position:absolute;
    z-index:-1;
    top:0;
    left:0;
  }
  .team-item-page .copy h1 {
    font-family: 'Neutraface 2 Text Bold';
    font-size:30px;
    line-height:100%;
    padding-bottom:0;
  }
  .team-item-page .copy h2 {
    font-family: 'Neutraface 2 Text Book';
    font-size:25px;
    line-height:100%;
    color:#9deeca;
  }
  .team-item-page .copy .description {
    font-family: 'Neutraface 2 Text Light';
    font-size:15px;
    line-height:130%;
    max-width:700px;
  }

  .team-item-page .copy .enjoys {
    margin:2rem 0;
  }
  .team-item-page .copy .enjoys h3 {
    font-family: 'Neutraface 2 Text Demi';
    font-size:15px;
    line-height:100%;
    margin:0; padding:0;
    margin-bottom:1.5rem;
  }
  .team-item-page .copy .enjoys ul {
    list-style:none;
    list-style-type:none;
    margin:0; padding:0;
    text-align:center;
  }
  .team-item-page .copy .enjoys li {
    font-family: 'Neutraface 2 Text Book';
    font-size:15px;
    line-height:100%;
    display:block;
    color:#a3ebcf;
    margin-bottom:1.5rem;
    line-height:100%;
  }
  .team-item-page .copy .enjoys li:before { display:block; content:""; width:15px; height:25px; background-repeat:no-repeat; margin:0 auto;}
  .team-item-page .copy .enjoys li:nth-child(1):before { background-image:url('../img/enjoys/team-enjoys-icon-1.png'); }
  .team-item-page .copy .enjoys li:nth-child(2):before { background-image:url('../img/enjoys/team-enjoys-icon-2.png'); }
  .team-item-page .copy .enjoys li:nth-child(3):before { background-image:url('../img/enjoys/team-enjoys-icon-3.png'); }
  .team-item-page .copy .enjoys li:nth-child(4):before { background-image:url('../img/enjoys/team-enjoys-icon-4.png'); }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
}

/*// Large and Up */

@media only screen and (min-width: 993px) {
  .team-item-page {}
  .team-item-page .image {
    position:relative;
    z-index:2;
  }
  .team-item-page .copy {
    margin-top:90px;
    z-index:1;
    min-height:600px;
    padding:4rem 6rem;
  }
  .team-item-page .copy:after {
    width:120%;
    left:-20%;
  }
  .team-item-page .copy h1 {}
  .team-item-page .copy h2 {}
  .team-item-page .copy .description {}
  .team-item-page .copy .enjoys {}
  .team-item-page .copy .enjoys h3 {}
  .team-item-page .copy .enjoys ul {
    text-align:left;
  }
  .team-item-page .copy .enjoys li {}
  .team-item-page .copy .enjoys li:before { display:inline-block; float:left; margin:0; margin-right:1.5rem;}
}


*,
*:before,
*:after {
  box-sizing: border-box;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

img{
  max-width: 100%;
  height: auto;
}

html{
  font-size: 62.5%;
  font-weight: 400;
}

main{
  overflow: hidden;
  //margin-top: 4rem;
}

.gradientfadeout {
  background: #191919;
  color: #fff;
}

h2.decorative{
  &:after {
    content: url('../img/header-bar-ring.png');
    padding-left: 2rem;
  }
}

.bt-1{
  border-top: 1px solid black !important;
}
.bb-1{
  border-bottom: 1px solid black !important;
}
.bl-1{
  border-left: 1px solid black !important;
}
.br-1{
  border-right: 1px solid black !important;
}

.bt-0{
  border-top: none !important;
}
.bb-0{
  border-bottom: none !important;
}
.bl-0{
  border-left: none !important;
}
.br-0{
  border-right: none !important;
}

h2{
  //color: #123948;
}


body {
  font-family: $font-text;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //-webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  margin: 0;
  color: #222427;
  padding: 0;
  background-color: #fff;
  //background-color: gray;

  &.no-scroll {
    overflow: hidden;
  }
}

figure{
  padding: 0;
  margin: 0;
}

.wrapper {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
  width: 100%;
  position: relative;


  &--narrow {
    @extend .wrapper;
    max-width: 840px;
  }
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.for-readers {
  text-indent: -999999px;
  position: absolute;
  left: -999999999px;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
  &.input{
    @extend .text-left;
    padding-left: 0;
  }
}

section{
  padding: 3rem 0;
}

@media (min-width: $media-sm){
  .gradientfadeout-background {
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: right;
  }
  .gradientfadeout {
    background: #191919;
    background: -moz-linear-gradient(left, rgba(25,25,25,1) 0%, rgba(25,25,25,1) 64%, rgba(25,25,25,0.54) 100%);
    background: -webkit-linear-gradient(left, rgba(25,25,25,1) 0%,rgba(25,25,25,1) 64%,rgba(25,25,25,0.54) 100%);
    background: linear-gradient(to right, rgba(25,25,25,1) 0%,rgba(25,25,25,1) 64%,rgba(25,25,25,0.54) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#191919', endColorstr='#8a191919',GradientType=1 );
  }
}
@media (min-width: 601px){
  html{
    padding: 20px 20px 0;
  }
}
  @media (max-width: $media-sm){
    .wrapper{
      padding: 0 1rem;
    }
  .wsnw{
    white-space: normal;
  }
}

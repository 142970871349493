div.single-post{
 width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 50px;

  ul, ol{
    list-style: initial;
    padding: 0 0 40px 40px;
    margin: initial;
    li{
      list-style: initial;
    }
  }
  ol li{
    list-style: auto;
  }
}

.page-template{
  .entry-content-page > .wrapper{
    ul.md-left-bar.md2-pl.mb-4.mt-4{
      padding-top: 20px;
    }
    ol:not([class]),
    ul:not([class]){
      list-style: initial;
      padding: 0 0 20px 40px;
      margin: initial;
      ul, li{
        padding-bottom: 0;
      }
      li{
        list-style: initial;
      }
    }
    ol:not([class]) li{
      list-style: auto;
    }
  }
}

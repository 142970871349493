a.nolink{
  color: black;
  font-weight: bold;
  text-decoration-color: black;

  &:hover{
    text-decoration: underline;
  }
}

a.ss-link{
  text-transform: uppercase;color: black;
  border-bottom: 2px solid $color-primary;
  display: inline-block;
  text-decoration: none;
  color: #212721;
  background-color: transparent;
  border-bottom: 2px solid #9deeca;
  font-family: 'Neutraface 2 Display Bold';
  font-size: 1.4rem;
  font-weight: normal;
  &:hover{
    text-decoration: none;
  }
}
a,
.link {
  color: #9deeca;
  text-decoration: none;
  transition: $link-transition;
  cursor: pointer;

  &:active,
  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &--inverse {
    color: $color-text;
    transition: $link-transition;
    border-color: #000;
  }

  &--back {
    display: block;
    font-size: 14px;
    letter-spacing: 0.04em;
    padding-left: 25px;
    position: relative;
    text-transform: uppercase;

    span {
      color: lighten($color-text, 40%);
      transition: $link-transition;
    }

    &:before {
      border: solid $color-text;
      border-width: 0 0 1px 1px;
      content: '';
      height: 12px;
      left: 5px;
      position: absolute;
      top: 3px;
      transform: rotate(45deg);
      width: 12px;
    }

    &:active,
    &:focus,
    &:hover {
      color: $color-text;

      span {
        color: $color-primary;
      }
    }
  }

  &-white {
    border-bottom: 1px solid transparent;
    color: $color-white;
    transition: $link-transition;

    &:active,
    &:focus,
    &:hover {
      border-bottom: 1px solid $color-white;
      color: $color-white;
    }
  }

  &-green-block {
    color: $color-text;
    transition: $btn-transition;

    &:active,
    &:focus,
    &:hover {
      color: $color-white;
      background: $color-primary;
    }
  }
}

.buttons{
  display: flex;
  margin: 0 -15px 30px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  &--center{
    @extend .buttons;
    justify-content: center;
  }

  & > a, & > button, & > form{
    margin: 10px 15px;
  }
}

span#cn-notice-text a{
  color: #b5cde9;
}

a#cn-accept-cookie,
.btn,
button {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}

a#cn-accept-cookie{
  background-color: $color-secondary;
}
.btn {
  color: #cdf0f8;
  //min-height: 70px;
  line-height: 1.2em;
  font-family: $font-bold;
  font-weight: 600;
  font-size: 1.4rem;
  //min-width: 11.5rem;
  padding: 1rem 0;
  display: table;
  text-align: center;
  transition: $btn-transition;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 140%;


  &:active,
  &:focus,
  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }

  &.isLoading{
    opacity: .8;
    pointer-events: none;
  }

  &--readmore{
    &:after{
      font-family: "FontAwesome";
      content: "\f178";
      margin-left: 1em;
      font-size: 80%;
    }
  }

  &--black {
    border: 1px black solid;
    color: white;
    background-color: black;
    text-transform: uppercase;
    min-width: 210px;
    padding: 1rem;


    &.btn--inverse {
      color: black;
      background-color: transparent;

      &:active,
      &:focus,
      &:hover {
        background-color: $color-text;
        color: white;
      }
    }

    &:active,
    &:focus,
    &:hover {
      color: $color-primary;

      span {
        color: $color-primary;
      }
    }
  }

  &--white {
    border: 1px solid white;
    color: black;
    transition: $link-transition;
    background-color: white;
    min-width: 210px;
    padding: 1rem;

    &:active,
    &:focus,
    &:hover {
      border-bottom: 1px solid black;
      background-color: black;
      color: $color-white;
    }

    &.btn--inverse{
      background-color: transparent;
      color: white;


      &:active,
      &:focus,
      &:hover {
        background-color: white;
        color: black;
      }

    }
  }
  &--center{
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
  &--right{
    display: table;
    margin-left: auto;
    margin-right: 0;
  }
}

@media (max-width: $media-xs){
  .buttons{
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }

  .btn--right{
    margin-right: auto;
  }
}

/* serviceSection - modified for inner pages */
.serviceSectionInnerPages {
  top: -8rem;
  position: relative;
  margin-bottom: -8rem;
  z-index: 99;
}
.serviceSectionInnerPages .serviceSection {
  background-color: #222427;
  color: #fff;
  p.icon{
    svg * {
      fill: white;
    }
  }
}
section.featured-work.portfolio{
  .btn{
    padding: 1.2rem 3rem;
    min-width: 210px;
  }
}
.serviceSectionInnerPages .serviceSection p.icon span {
  color: #fff;
}
.serviceSectionInnerPages .serviceSection:hover .icon span {
  color: #9deeca;
}

@media only screen and (min-width: 0px) {

  /* serviceSection */
  .serviceSection-banner .col {
    height: auto;
  }
  .serviceSection p.icon {
    svg {
      height: 60px;
      fill: #9deeca;
      font-size: 300%;
    }
    svg *{
      fill: #9deeca;
    }
  }
  .serviceSection {
    min-height: 36rem;
    z-index: 2;
    position: relative;
    background-color: #222427;
    color: #fff;
  }
  .serviceSection p.icon {
    min-height: 60px;
  }
  .serviceSection p.icon span {
    color: #a5d8c2;
    font-size: 300%;
  }
  .serviceSection:hover .icon span {
    color: #a5d8c2;
  }

  .serviceSection p.icon span span {
    display: none;
  }
  .serviceSection .sm-btn-primary,
  .serviceSection .sm-btn-primary:link {
    visibility: visible;
    background-color: #a5d8c2;
    color: #222427;
  }
  .serviceSection:hover .sm-btn-primary:hover {
    color: #fff;
  }
}

@media only screen and (min-width: 601px) {
  .serviceSection {
    background-color:#fff;
    color:#222427;
  }
  .serviceSection:hover {
    background-color: #222427;
    color: #fff;
  }
  .serviceSection p.icon {
    svg {
      height: 60px;
      fill: #222427;
      font-size: 300%;
    }
    svg {
      fill: #222427;
    }
    svg *{
      fill: #222427;
    }
  }
  .serviceSection {
    .sm-btn-primary,
    .sm-btn-primary:link {
      visibility: hidden;
      background-color: #222427;
      color: #fff;
    }
    &:hover {
     p.icon{
       svg {
         fill: #a5d8c2;
       }
       svg *{
         fill: #a5d8c2;
       }
     }
      .sm-btn-primary,
      .sm-btn-primary:link {
        visibility: visible;
        background-color: #a5d8c2;
        color: #222427;
      }
    }
  }
  .serviceSection
  .serviceSectionInnerPages {
    margin-top: -10rem;
  }

  .serviceSectionInnerPages {
   top: -10rem;
    margin-bottom: -10rem;
  }

}
/*// Large and Up */
@media only screen and (min-width: 993px) {
  .serviceSection .sm-btn-primary,
  .serviceSection .sm-btn-primary:link {
    visibility: hidden;
  }
  .serviceSection {
    -moz-transition: background 1s;
    -webkit-transition: background 1s;
    transition: background 1s;
  }

  .serviceSection h3,
  .serviceSection p {
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    transition: all 1s;
  }

  .serviceBoxout {
    height: 100%;
    max-height: 500px;
    margin-top: 60px;
    margin-bottom: 60px;

  }

  .serviceSectionInnerPages {
    top: -40rem;
    margin-bottom: -40rem;
  }
}



@media only screen and (max-width: $media-sm) {
  .serviceSection .sm-btn-primary, .serviceSection .sm-btn-primary:link{
    visibility: visible;
  }

  .serviceSectionInnerPages {
    position: relative;
    top: -17rem;
    margin-bottom:-17rem;
  }
  section.featured-work.portfolio{
    .container.homepagePortfolio{
      .sm8-my{
        margin-top: 4rem;
        margin-bottom: 0rem;
      }
    }
  }
}

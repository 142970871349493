@media only screen and (min-width: 0) {
  #cookie-notice{
    background-color: #222f36 !important;

    p{
      font-size: 14px;
      line-height: 14px;
      color: #ccc;
      font-family: 'Neutraface 2 Text light', sans-serif;

      a{
        color: white;
        text-decoration: underline;
        font-family: 'Neutraface 2 Text Bold';
      }
    }
  }
  a#cn-close-notice{
    display: none;
  }

  body{
    #cookie-notice .cookie-notice-container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;

     .cn-text-container{
       a{
         color: white;
         text-decoration: underline;
         font-family: $font-bold;
       }
     }
    }
  }
  #cookie-notice .cn-button{
    margin: 0 auto;
    color: black;
    background-color: white;
    text-transform: uppercase;
  }
}

.info-boxes-row > div {
  display: inline-block;
}
.section-two-columns{
  background-color: #f4f4f4;
  ul{
    padding-left: 4rem;

    &.sm-left-bar{
      list-style: none;
    }

    li{
      margin-bottom: 2rem;

      & + p{
        padding-bottom: 0;
      }
    }
  }
}
.entry-content-page .info-boxes{
  padding-top: 6rem;
  h2{
    padding-bottom: 0;
  }
}
@media only screen and (max-width: $media-sm) {
  .container.containerServiceBoxout .sm6-mb{
    margin-bottom: 0;
  }
}

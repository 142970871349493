@media only screen and (min-width: 993px) {
  .containerServiceBoxout {
    display: flex;
  }

  .containerServiceBoxout {
    margin-top: 60px;
  }

  .containerServiceBoxout .col.sm12 {
    height: 100%;
  }

  .containerServiceBoxout .serviceBoxout {
    height: 100%;
    max-height: none;
    margin-top: 0;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (min-width: 0px) {
  .containerServiceBoxout{
    margin-top: 45px;
  }
  .serviceBoxout {
    background-color: #222427;
    color: #fff;
  }
  .serviceBoxout ul {
    padding:0;
    margin:0;
  }
  .serviceBoxout ul li{
    list-style:none;
    padding:1.25rem 0;
    margin:-19px 0 0 0;
    text-indent:-62px;
    padding-left:62px;
    position:relative;
    display:block;
    min-height:38px
  }
  .serviceBoxout ul li:before {
    content:"";
    background-image:url('../img/Icon-services.png');
    background-repeat:no-repeat;
    background-position-y:top;
    display:inline-block;
    width:32px;
    height:38px;
    margin-right:30px;
    top:19px;
    position:relative;
  }
}

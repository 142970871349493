@media only screen and (min-width: 0) {
  .wpcf7 .ajax-loader{
    display: none !important;
  }
  .bw-2{
    border-width: 2px;
  }
  [class*="sm-btn"],[class*="sm-btn"]:link,[class*="sm-btn"]:visited,[class*="sm-btn"]:hover,[class*="sm-btn"]:focus,[class*="sm-btn"]:active {
    //border:none;
    background-image: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    //font-size: 100%;
    display: inline-block;
    zoom:1;white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //font-family: inherit;
    text-decoration: none;
    outline: none
  }

  [class*="sm-btn"] {
    //background-color: #9e9e9e;
    color: #fff
  }

  .sm-btn-secondary{
    text-transform: uppercase;
    color: #212721;
    background-color:transparent;
    border-bottom: 2px solid #9deeca;
    border-color: #9deeca;
    font-family: 'Neutraface 2 Display Bold';
    font-size: 1.4rem;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
}

@media only screen and (min-width: 601px) {
  [class*="sm-btn"] {
  }

  [class*="sm-btn"]:link {
  }

  [class*="sm-btn"]:visited {
  }

  [class*="sm-btn"]:hover {
  }

  [class*="sm-btn"]:focus {
  }

  [class*="sm-btn"]:active {
    box-shadow: inset 0 0 10px 0 rgba(0,0,0,.4)
  }
}

@media only screen and (min-width: 993px) {
  [class*="sm-btn"] {
  }

  [class*="sm-btn"]:link {
  }

  [class*="sm-btn"]:visited {
  }

  [class*="sm-btn"]:hover {
    box-shadow: inset 0 0 10px 0 rgba(0,0,0,.2)
  }

  [class*="sm-btn"]:focus {
  }

  [class*="sm-btn"]:active {
    box-shadow: inset 0 0 10px 0 rgba(0,0,0,.4)
  }
}

@media only screen and (min-width: 0) {
  .sm-btn {
    padding:.5rem 1rem
  }
}

@media only screen and (min-width: 601px) {
  .md-btn {
    padding:1rem 2rem
  }
}

@media only screen and (min-width: 993px) {
  .lg-btn {
    padding:1.5rem 2.5rem
  }
}

@media only screen and (min-width: 0) {
  [class*="sm-btn"][class*="ssdefault"] {
  }

  [class*="sm-btn"][class*="ssdefault"]:link {
  }

  [class*="sm-btn"][class*="ssdefault"]:visited {
  }

  [class*="sm-btn"][class*="ssdefault"]:hover {
  }

  [class*="sm-btn"][class*="ssdefault"]:focus {
  }

  [class*="sm-btn"][class*="ssdefault"]:active {
  }

  [class*="sm-btn"][class*="ssprimary"] {
  }

  [class*="sm-btn"][class*="ssprimary"]:link {
  }

  [class*="sm-btn"][class*="ssprimary"]:visited {
  }

  [class*="sm-btn"][class*="ssprimary"]:hover {
  }

  [class*="sm-btn"][class*="ssprimary"]:focus {
  }

  [class*="sm-btn"][class*="ssprimary"]:active {
  }

  [class*="sm-btn"][class*="sssecondary"] {
  }

  [class*="sm-btn"][class*="sssecondary"]:link {
  }

  [class*="sm-btn"][class*="sssecondary"]:visited {
  }

  [class*="sm-btn"][class*="sssecondary"]:hover {
  }

  [class*="sm-btn"][class*="sssecondary"]:focus {
  }

  [class*="sm-btn"][class*="sssecondary"]:active {
  }

  [class*="sm-btn"][class*="sssuccess"] {
  }

  [class*="sm-btn"][class*="sssuccess"]:link {
  }

  [class*="sm-btn"][class*="sssuccess"]:visited {
  }

  [class*="sm-btn"][class*="sssuccess"]:hover {
  }

  [class*="sm-btn"][class*="sssuccess"]:focus {
  }

  [class*="sm-btn"][class*="sssuccess"]:active {
  }

  [class*="sm-btn"][class*="ssinfo"] {
  }

  [class*="sm-btn"][class*="ssinfo"]:link {
  }

  [class*="sm-btn"][class*="ssinfo"]:visited {
  }

  [class*="sm-btn"][class*="ssinfo"]:hover {
  }

  [class*="sm-btn"][class*="ssinfo"]:focus {
  }

  [class*="sm-btn"][class*="ssinfo"]:active {
  }

  [class*="sm-btn"][class*="sswarning"] {
  }

  [class*="sm-btn"][class*="sswarning"]:link {
  }

  [class*="sm-btn"][class*="sswarning"]:visited {
  }

  [class*="sm-btn"][class*="sswarning"]:hover {
  }

  [class*="sm-btn"][class*="sswarning"]:focus {
  }

  [class*="sm-btn"][class*="sswarning"]:active {
  }

  [class*="sm-btn"][class*="ssdanger"] {
  }

  [class*="sm-btn"][class*="ssdanger"]:link {
  }

  [class*="sm-btn"][class*="ssdanger"]:visited {
  }

  [class*="sm-btn"][class*="ssdanger"]:hover {
  }

  [class*="sm-btn"][class*="ssdanger"]:focus {
  }

  [class*="sm-btn"][class*="ssdanger"]:active {
  }

  [class*="sm-btn"][class*="sslink"] {
  }

  [class*="sm-btn"][class*="sslink"]:link {
  }

  [class*="sm-btn"][class*="sslink"]:visited {
  }

  [class*="sm-btn"][class*="sslink"]:hover {
  }

  [class*="sm-btn"][class*="sslink"]:focus {
  }

  [class*="sm-btn"][class*="sslink"]:active {
  }

  [class*="sm-btn"][class*="ssdisabled"] {
    cursor: not-allowed
  }

  [class*="sm-btn"][class*="ssdisabled"]:link {
  }

  [class*="sm-btn"][class*="ssdisabled"]:visited {
  }

  [class*="sm-btn"][class*="ssdisabled"]:hover {
  }

  [class*="sm-btn"][class*="ssdisabled"]:focus {
    box-shadow: none
  }

  [class*="sm-btn"][class*="ssdisabled"]:active {
    box-shadow: none
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
  [class*="sm-btn"][class*="ssdisabled"] {
  }

  [class*="sm-btn"][class*="ssdisabled"]:link {
  }

  [class*="sm-btn"][class*="ssdisabled"]:visited {
  }

  [class*="sm-btn"][class*="ssdisabled"]:hover {
    box-shadow: none
  }

  [class*="sm-btn"][class*="ssdisabled"]:focus {
  }

  [class*="sm-btn"][class*="ssdisabled"]:active {
  }
}

@media only screen and (min-width: 0) {
  form {
  }

  input {
  }

  input[type=text] {
  }

  input[type=password] {
  }

  input[type=number] {
  }

  input[type=reset] {
  }

  input[type=radio] {
  }

  input[type=checkbox] {
  }

  input[type=button] {
  }

  input[type=color] {
  }

  input[type=date] {
  }

  input[type=detetime-local] {
  }

  input[type=month] {
  }

  input[type=range] {
  }

  input[type=search] {
  }

  input[type=tel] {
  }

  input[type=time] {
  }

  input[type=url] {
  }

  input[type=week] {
  }

  input[type=submit] {
  }

  button {
  }

  button[type=button] {
  }

  button[type=submit] {
  }

  button[type=reset] {
  }

  select {
  }

  datalist {
  }

  optiongroup {
  }

  option {
  }

  textarea {
  }

  label {
  }

  fieldset {
  }

  fieldset legend {
  }

  keygen {
  }

  output {
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
}

@media only screen and (min-width: 0) {
  [class*="ssform"],[class*="ssform"]:link,[class*="ssform"]:visited,[class*="ssform"]:hover,[class*="ssform"]:focus,[class*="ssform"]:active {
    display:block;
    text-indent: 0;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    zoom:1;white-space: nowrap;
    vertical-align: middle;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    border-style: solid;
    border-width: 1px
  }

  [class*="ssform"] {
    background: none;
    background-color:transparent;
    background-image: none;
    border-color: #ddd;
    border-width: 1px
  }

  [class*="ssform"]::placeholder {
    color: inherit;
    opacity: .6
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
}

@media only screen and (min-width: 0) {
  .sm-form {
    padding:.5rem 1rem
  }

  .sm1-form {
  }

  .sm2-form {
  }
}

@media only screen and (min-width: 601px) {
  .md-form {
    padding:1rem 2rem
  }

  .md1-form {
  }

  .md2-form {
  }
}

@media only screen and (min-width: 993px) {
  .lg-form {
    padding:1.5rem 2.5rem
  }

  .lg1-form {
  }

  .lg2-form {
  }
}

@media only screen and (min-width: 0) {
  label[class*="ssform"],label[class*="ssform"]:link,label[class*="ssform"]:visited,label[class*="ssform"]:hover,label[class*="ssform"]:focus,label[class*="ssform"]:active {
    border:0;
    padding: 0;
    margin-bottom: .5rem
  }

  [class*="ssform"][type=text] {
  }

  [class*="ssform"][type=password] {
  }

  [class*="ssform"][type=search] {
  }

  [class*="ssform"][type=url] {
  }

  [class*="ssform"][type=number] {
  }

  [class*="ssform"][type=tel] {
  }

  [class*="ssform"][type=radio] {
  }

  [class*="ssform"][type=checkbox] {
  }

  [class*="ssform"][type=color] {
  }

  [class*="ssform"][type=range] {
  }

  [class*="ssform"][type=time] {
  }

  [class*="ssform"][type=date] {
  }

  [class*="ssform"][type=detetime-local] {
  }

  [class*="ssform"][type=week] {
  }

  [class*="ssform"][type=month] {
  }

  [class*="ssform"][type=submit] {
  }

  [class*="ssform"][type=button] {
  }

  [class*="ssform"][type=reset] {
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
}

@media only screen and (min-width: 0) {
  html {
    margin:0;
    padding: 0
  }

  body {
    margin: 0;
    padding: 0
  }

  body>#form1 {
    margin: 0;
    padding: 0
  }

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box
  }

  .container {
    margin: 0 auto
  }

  .section {
    padding: 0
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
  [class*="container"] {
    max-width:1200px
  }
}

@media only screen and (min-width: 0) {
  .row,
  .sm1-row,
  .sm2-row,
  .sm3-row,
  .sm4-row,
  .sm5-row,
  .sm6-row,
  .sm7-row,
  .sm8-row,
  .sm9-row,
  .sm10-row,
  .sm11-row,
  .sm12-row {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row
  }

  .sm1-row {
    margin:-1rem;
    width: auto;
    max-width: none
  }

  .sm1-row>.col {
    padding: 1rem
  }

  .sm2-row {
    margin: -2rem
  }

  .sm2-row>.col {
    padding: 2rem
  }

  .sm3-row {
    margin: 0 -3rem
  }

  .sm3-row>.col {
    padding: 3rem
  }

  .sm4-row {
    margin: -4rem
  }

  .sm4-row>.col {
    padding: 4rem
  }

  .sm5-row {
    margin: -5rem
  }

  .sm5-row>.col {
    padding: 5rem
  }

  .sm6-row {
    margin: -6rem
  }

  .sm6-row>.col {
    padding: 6rem
  }

  .sm7-row {
    margin: -7rem
  }

  .sm7-row>.col {
    padding: 7rem
  }

  .sm8-row {
    margin: -8rem
  }

  .sm8-row>.col {
    padding: 8rem
  }

  .sm9-row {
    margin: -9rem
  }

  .sm9-row>.col {
    padding: 9rem
  }

  .sm10-row {
    margin: -10rem
  }

  .sm10-row>.col {
    padding: 10rem
  }

  .sm11-row {
    margin: -11rem
  }

  .sm11-row>.col {
    padding: 11rem
  }

  .sm12-row {
    margin: -12rem
  }

  .sm12-row>.col {
    padding: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md1-row,
  .md2-row,
  .md3-row,
  .md4-row,
  .md5-row,
  .md6-row,
  .md7-row,
  .md8-row,
  .md9-row,
  .md10-row,
  .md11-row,
  .md12-row {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row
  }

  .md1-row {
    margin:-1rem
  }

  .md1-row>.col {
    padding: 1rem
  }

  .md2-row {
    margin: -2rem
  }

  .md2-row>.col {
    padding: 2rem
  }

  .md3-row {
    margin: -3rem
  }

  .md3-row>.col {
    padding: 3rem
  }

  .md4-row {
    margin: -4rem
  }

  .md4-row>.col {
    padding: 4rem
  }

  .md5-row {
    margin: -5rem
  }

  .md5-row>.col {
    padding: 5rem
  }

  .md6-row {
    margin: -6rem
  }

  .md6-row>.col {
    padding: 6rem
  }

  .md7-row {
    margin: -7rem
  }

  .md7-row>.col {
    padding: 7rem
  }

  .md8-row {
    margin: -8rem
  }

  .md8-row>.col {
    padding: 8rem
  }

  .md9-row {
    margin: -9rem
  }

  .md9-row>.col {
    padding: 9rem
  }

  .md10-row {
    margin: -10rem
  }

  .md10-row>.col {
    padding: 10rem
  }

  .md11-row {
    margin: -11rem
  }

  .md11-row>.col {
    padding: 11rem
  }

  .md12-row {
    margin: -12rem
  }

  .md12-row>.col {
    padding: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg1-row,
  .lg2-row,
  .lg3-row,
  .lg4-row,
  .lg5-row,
  .lg6-row,
  .lg7-row,
  .lg8-row,
  .lg9-row,
  .lg10-row,
  .lg11-row,
  .lg12-row {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row
  }
  .lg1-row {
    margin:-1rem
  }

  .lg1-row>.col {
    padding: 1rem
  }

  .lg2-row {
    margin: -2rem
  }

  .lg2-row>.col {
    padding: 2rem
  }

  .lg3-row {
    margin: -3rem
  }

  .lg3-row>.col {
    padding: 3rem
  }

  .lg4-row {
    margin: -4rem
  }

  .lg4-row>.col {
    padding: 4rem
  }

  .lg5-row {
    margin: -5rem
  }

  .lg5-row>.col {
    padding: 5rem
  }

  .lg6-row {
    margin: -6rem
  }

  .lg6-row>.col {
    padding: 6rem
  }

  .lg7-row {
    margin: -7rem
  }

  .lg7-row>.col {
    padding: 7rem
  }

  .lg8-row {
    margin: -8rem
  }

  .lg8-row>.col {
    padding: 8rem
  }

  .lg9-row {
    margin: -9rem
  }

  .lg9-row>.col {
    padding: 9rem
  }

  .lg10-row {
    margin: -10rem
  }

  .lg10-row>.col {
    padding: 10rem
  }

  .lg11-row {
    margin: -11rem
  }

  .lg11-row>.col {
    padding: 11rem
  }

  .lg12-row {
    margin: -12rem
  }

  .lg12-row>.col {
    padding: 12rem
  }
}

@media only screen and (min-width: 0) {
  .col {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-grow: 1;
    order: 1
  }
  .col.sm1 {
    width:8.3333333333%;
    max-width: 8.3333333333%
  }

  .col.sm2 {
    width: 16.66666666667%;
    max-width: 16.66666666667%
  }

  .col.sm3 {
    width: 25%;
    max-width: 25%
  }

  .col.sm4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .col.sm5 {
    width: 41.66666666667%;
    max-width: 41.66666666667%
  }

  .col.sm6 {
    width: 50%;
    max-width: 50%
  }

  .col.sm7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .col.sm8 {
    width: 66.66666666667%;
    max-width: 66.66666666667%
  }

  .col.sm9 {
    width: 75%;
    max-width: 75%
  }

  .col.sm10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .col.sm11 {
    width: 91.66666666667%;
    max-width: 91.66666666667%
  }

  .col.sm12 {
    width: 100%;
    max-width: 100%
  }
}

@media only screen and (min-width: 601px) {
  .col.md1 {
    width:8.3333333333%;
    max-width: 8.3333333333%
  }

  .col.md2 {
    width: 16.66666666667%;
    max-width: 16.66666666667%
  }

  .col.md3 {
    width: 25%;
    max-width: 25%
  }

  .col.md4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .col.md5 {
    width: 41.66666666667%;
    max-width: 41.66666666667%
  }

  .col.md6 {
    width: 50%;
    max-width: 50%
  }

  .col.md7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .col.md8 {
    width: 66.66666666667%;
    max-width: 66.66666666667%
  }

  .col.md9 {
    width: 75%;
    max-width: 75%
  }

  .col.md10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .col.md11 {
    width: 91.66666666667%;
    max-width: 91.66666666667%
  }

  .col.md12 {
    width: 100%;
    max-width: 100%
  }
}

@media only screen and (min-width: 993px) {
  .col.lg1 {
    width:8.3333333333%;
    max-width: 8.3333333333%
  }

  .col.lg2 {
    width: 16.66666666667%;
    max-width: 16.66666666667%
  }

  .col.lg3 {
    width: 25%;
    max-width: 25%
  }

  .col.lg4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .col.lg5 {
    width: 41.66666666667%;
    max-width: 41.66666666667%
  }

  .col.lg6 {
    width: 50%;
    max-width: 50%
  }

  .col.lg7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .col.lg8 {
    width: 66.66666666667%;
    max-width: 66.66666666667%
  }

  .col.lg9 {
    width: 75%;
    max-width: 75%
  }

  .col.lg10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .col.lg11 {
    width: 91.66666666667%;
    max-width: 91.66666666667%
  }

  .col.lg12 {
    width: 100%;
    max-width: 100%
  }
}

@media only screen and (min-width: 0) {
  .col.sm-top {
    align-self:flex-start
  }

  .col.sm-bottom {
    align-self: flex-end
  }

  .col.sm-middle {
    align-self: center
  }

  .col.sm-fit {
    align-self: stretch
  }

  .col.sm-baseline {
    align-self: baseline
  }
}

@media only screen and (min-width: 601px) {
  .col.md-top {
    align-self:flex-start
  }

  .col.md-bottom {
    align-self: flex-end
  }

  .col.md-middle {
    align-self: center
  }

  .col.md-fit {
    align-self: stretch
  }

  .col.md-baseline {
    align-self: baseline
  }
}

@media only screen and (min-width: 993px) {
  .col.lg-top {
    align-self:flex-start
  }

  .col.lg-bottom {
    align-self: flex-end
  }

  .col.lg-middle {
    align-self: center
  }

  .col.lg-fit {
    align-self: stretch
  }

  .col.lg-baseline {
    align-self: baseline
  }
}

@media only screen and (min-width: 0) {
  .col.sm1-offset {
    margin-left:8.3333333333%
  }

  .col.sm2-offset {
    margin-left: 16.66666666667%
  }

  .col.sm3-offset {
    margin-left: 25%
  }

  .col.sm4-offset {
    margin-left: 33.3333333333%
  }

  .col.sm5-offset {
    margin-left: 41.66666666667%
  }

  .col.sm6-offset {
    margin-left: 50%
  }

  .col.sm7-offset {
    margin-left: 58.3333333333%
  }

  .col.sm8-offset {
    margin-left: 66.66666666667%
  }

  .col.sm9-offset {
    margin-left: 75%
  }

  .col.sm10-offset {
    margin-left: 83.3333333333%
  }

  .col.sm11-offset {
    margin-left: 91.66666666667%
  }

  .col.sm12-offset {
    margin-left: 100%
  }
}

@media only screen and (min-width: 601px) {
  .col.md1-offset {
    margin-left:8.3333333333%
  }

  .col.md2-offset {
    margin-left: 16.66666666667%
  }

  .col.md3-offset {
    margin-left: 25%
  }

  .col.md4-offset {
    margin-left: 33.3333333333%
  }

  .col.md5-offset {
    margin-left: 41.66666666667%
  }

  .col.md6-offset {
    margin-left: 50%
  }

  .col.md7-offset {
    margin-left: 58.3333333333%
  }

  .col.md8-offset {
    margin-left: 66.66666666667%
  }

  .col.md9-offset {
    margin-left: 75%
  }

  .col.md10-offset {
    margin-left: 83.3333333333%
  }

  .col.md11-offset {
    margin-left: 91.66666666667%
  }

  .col.md12-offset {
    margin-left: 100%
  }
}

@media only screen and (min-width: 993px) {
  .col.lg1-offset {
    margin-left:8.3333333333%
  }

  .col.lg2-offset {
    margin-left: 16.66666666667%
  }

  .col.lg3-offset {
    margin-left: 25%
  }

  .col.lg4-offset {
    margin-left: 33.3333333333%
  }

  .col.lg5-offset {
    margin-left: 41.66666666667%
  }

  .col.lg6-offset {
    margin-left: 50%
  }

  .col.lg7-offset {
    margin-left: 58.3333333333%
  }

  .col.lg8-offset {
    margin-left: 66.66666666667%
  }

  .col.lg9-offset {
    margin-left: 75%
  }

  .col.lg10-offset {
    margin-left: 83.3333333333%
  }

  .col.lg11-offset {
    margin-left: 91.66666666667%
  }

  .col.lg12-offset {
    margin-left: 100%
  }
}

@media only screen and (min-width: 0) {
  .col.sm1-order {
    order:1
  }

  .col.sm2-order {
    order: 2
  }

  .col.sm3-order {
    order: 3
  }

  .col.sm4-order {
    order: 4
  }

  .col.sm5-order {
    order: 5
  }

  .col.sm6-order {
    order: 6
  }

  .col.sm7-order {
    order: 7
  }

  .col.sm8-order {
    order: 8
  }

  .col.sm9-order {
    order: 9
  }

  .col.sm10-order {
    order: 10
  }

  .col.sm11-order {
    order: 11
  }

  .col.sm12-order {
    order: 12
  }
}

@media only screen and (min-width: 601px) {
  .col.md1-order {
    order:1
  }

  .col.md2-order {
    order: 2
  }

  .col.md3-order {
    order: 3
  }

  .col.md4-order {
    order: 4
  }

  .col.md5-order {
    order: 5
  }

  .col.md6-order {
    order: 6
  }

  .col.md7-order {
    order: 7
  }

  .col.md8-order {
    order: 8
  }

  .col.md9-order {
    order: 9
  }

  .col.md10-order {
    order: 10
  }

  .col.md11-order {
    order: 11
  }

  .col.md12-order {
    order: 12
  }
}

@media only screen and (min-width: 993px) {
  .col.lg1-order {
    order:1
  }

  .col.lg2-order {
    order: 2
  }

  .col.lg3-order {
    order: 3
  }

  .col.lg4-order {
    order: 4
  }

  .col.lg5-order {
    order: 5
  }

  .col.lg6-order {
    order: 6
  }

  .col.lg7-order {
    order: 7
  }

  .col.lg8-order {
    order: 8
  }

  .col.lg9-order {
    order: 9
  }

  .col.lg10-order {
    order: 10
  }

  .col.lg11-order {
    order: 11
  }

  .col.lg12-order {
    order: 12
  }
}

@media only screen and (min-width: 0) {
  .modal-trigger {
    cursor:pointer
  }

  .modal {
    display: none
  }

  .modal-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 199;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.2)
  }

  .modal-active {
    position: relative;
    z-index: 200;
    display: block;
    position: fixed;
    width: 90vw;
    height: 50vh;
    top: 25vh;
    right: 0;
    left: 0;
    max-width: 960px;
    margin: 0 auto;
    overflow: auto;
    -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.4);
    -moz-box-shadow: 0 0 20px 0 rgba(0,0,0,.4);
    box-shadow: 0 0 20px 0 rgba(0,0,0,.4);
    padding: 1rem;
    background-color: rgba(255,255,255,1)
  }

  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .5rem 1rem;
    cursor: pointer
  }

  .modal-close:before {
    content: '❌'
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
}

@media only screen and (min-width: 0) {
  .socialshare-facebook {
    background-color:#3b5998;
    color: #fff
  }

  .socialshare-linkedin {
    background-color: #0077b5;
    color: #fff
  }

  .socialshare-twitter {
    background-color: #0084b4;
    color: #fff
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
}

pre,code {
  padding: 0;
  margin: 0
}

pre {
  width: 100%;
  color: #3f3b36;
  background-color: #fff;
  font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,sans-serif;
  font-size: 13px;
  background-color: #eff0f1;
  word-wrap: normal;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  padding: 0 1rem;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word
}

pre>code {
  padding: 0;
  color: #3f3b36;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.6rem;
  text-shadow: none
}

@media only screen and (min-width: 0) {
  .tab-trigger {
    width:auto;
    float: left
  }

  .tab {
    display: none;
    float: right;
    margin: 0 0 0 -100%;
    width: 100%
  }

  .tab-trigger-active {
  }

  .tab-active {
    display: block
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
}

@media only screen and (min-width: 0) {
  .sm-align-left {
    text-align:left
  }

  .sm-align-center {
    text-align: center
  }

  .sm-align-right {
    text-align: right
  }
}

@media only screen and (min-width: 601px) {
  .md-align-left {
    text-align:left
  }

  .md-align-center {
    text-align: center
  }

  .md-align-right {
    text-align: right
  }
}

@media only screen and (min-width: 993px) {
  .lg-align-left {
    text-align:left
  }

  .lg-align-center {
    text-align: center
  }

  .lg-align-right {
    text-align: right
  }
}

@media only screen and (min-width: 0) {
  div[class*="ssdefault"] {
    background-color:#9e9e9e;
    color: #fff
  }

  div[class*="ssprimary"] {
    background-color: #3f51b5;
    color: #fff
  }

  div[class*="sssecondary"] {
    background-color: #2196f3;
    color: #fff
  }

  div[class*="sssuccess"] {
    background-color: #4caf50;
    color: #fff
  }

  div[class*="ssinfo"] {
    background-color: #03a9f4;
    color: #fff
  }

  div[class*="sswarning"] {
    background-color: #e67e22;
    color: #fff
  }

  div[class*="ssdanger"] {
    background-color: #f44336;
    color: #fff
  }

  div[class*="sslink"] {
    background-color: #2196f3;
    color: #fff
  }

  div[class*="ssdisabled"] {
    background-color: #9e9e9e;
    color: #fff
  }
}

@media only screen and (min-width: 601px) {
}

@media only screen and (min-width: 993px) {
}

@media only screen and (min-width: 0) {
  .sm-default {
  }

  .sm-primary {
  }

  .sm-secondary {
  }

  .sm-success {
  }

  .sm-info {
  }

  .sm-warning {
  }

  .sm-danger {
  }

  .sm-link {
  }

  .sm-disabled {
  }
}

@media only screen and (min-width: 601px) {
  .md-default {
  }

  .md-primary {
  }

  .md-secondary {
  }

  .md-success {
  }

  .md-info {
  }

  .md-warning {
  }

  .md-danger {
  }

  .md-link {
  }

  .md-disabled {
  }
}

@media only screen and (min-width: 993px) {
  .lg-default {
  }

  .lg-primary {
  }

  .lg-secondary {
  }

  .lg-success {
  }

  .lg-info {
  }

  .lg-warning {
  }

  .lg-danger {
  }

  .lg-link {
  }

  .lg-disabled {
  }
}

@media only screen and (min-width: 0) {
  .sm0-h {
    height:0
  }

  .sm1-h {
    height: 1rem
  }

  .sm2-h {
    height: 2rem
  }

  .sm3-h {
    height: 3rem
  }

  .sm4-h {
    height: 4rem
  }

  .sm5-h {
    height: 5rem
  }

  .sm6-h {
    height: 6rem
  }

  .sm7-h {
    height: 7rem
  }

  .sm8-h {
    height: 8rem
  }

  .sm9-h {
    height: 9rem
  }

  .sm10-h {
    height: 10rem
  }

  .sm11-h {
    height: 11rem
  }

  .sm12-h {
    height: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-h {
    height:0
  }

  .md1-h {
    height: 1rem
  }

  .md2-h {
    height: 2rem
  }

  .md3-h {
    height: 3rem
  }

  .md4-h {
    height: 4rem
  }

  .md5-h {
    height: 5rem
  }

  .md6-h {
    height: 6rem
  }

  .md7-h {
    height: 7rem
  }

  .md8-h {
    height: 8rem
  }

  .md9-h {
    height: 9rem
  }

  .md10-h {
    height: 10rem
  }

  .md11-h {
    height: 11rem
  }

  .md12-h {
    height: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-h {
    height:0
  }

  .lg1-h {
    height: 1rem
  }

  .lg2-h {
    height: 2rem
  }

  .lg3-h {
    height: 3rem
  }

  .lg4-h {
    height: 4rem
  }

  .lg5-h {
    height: 5rem
  }

  .lg6-h {
    height: 6rem
  }

  .lg7-h {
    height: 7rem
  }

  .lg8-h {
    height: 8rem
  }

  .lg9-h {
    height: 9rem
  }

  .lg10-h {
    height: 10rem
  }

  .lg11-h {
    height: 11rem
  }

  .lg12-h {
    height: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm-hide {
    display:none
  }

  .sm-show {
    display: inherit
  }
}

@media only screen and (min-width: 601px) {
  .md-hide {
    display:none
  }

  .md-show {
    display: inherit
  }
}

@media only screen and (min-width: 993px) {
  .lg-hide {
    display:none
  }

  .lg-show {
    display: inherit
  }
}

@media only screen and (min-width: 0) {
  .sm0-p {
    padding:0
  }

  .sm1-p {
    padding: 1rem
  }

  .sm2-p {
    padding: 2rem
  }

  .sm3-p {
    padding: 3rem
  }

  .sm4-p {
    padding: 4rem
  }

  .sm5-p {
    padding: 5rem
  }

  .sm6-p {
    padding: 6rem
  }

  .sm7-p {
    padding: 7rem
  }

  .sm8-p {
    padding: 8rem
  }

  .sm9-p {
    padding: 9rem
  }

  .sm10-p {
    padding: 10rem
  }

  .sm11-p {
    padding: 11rem
  }

  .sm12-p {
    padding: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-p {
    padding:0
  }

  .md1-p {
    padding: 1rem
  }

  .md2-p {
    padding: 2rem
  }

  .md3-p {
    padding: 3rem
  }

  .md4-p {
    padding: 4rem
  }

  .md5-p {
    padding: 5rem
  }

  .md6-p {
    padding: 6rem
  }

  .md7-p {
    padding: 7rem
  }

  .md8-p {
    padding: 8rem
  }

  .md9-p {
    padding: 9rem
  }

  .md10-p {
    padding: 10rem
  }

  .md11-p {
    padding: 11rem
  }

  .md12-p {
    padding: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-p {
    padding:0
  }

  .lg1-p {
    padding: 1rem
  }

  .lg2-p {
    padding: 2rem
  }

  .lg3-p {
    padding: 3rem
  }

  .lg4-p {
    padding: 4rem
  }

  .lg5-p {
    padding: 5rem
  }

  .lg6-p {
    padding: 6rem
  }

  .lg7-p {
    padding: 7rem
  }

  .lg8-p {
    padding: 8rem
  }

  .lg9-p {
    padding: 9rem
  }

  .lg10-p {
    padding: 10rem
  }

  .lg11-p {
    padding: 11rem
  }

  .lg12-p {
    padding: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-py {
    padding-top:0;
    padding-bottom: 0
  }

  .sm1-py {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .sm2-py {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .sm3-py {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .sm4-py {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .sm5-py {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .sm6-py {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .sm7-py {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .sm8-py {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .sm9-py {
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  .sm10-py {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .sm11-py {
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  .sm12-py {
    padding-top: 12rem;
    padding-bottom: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-py {
    padding-top:0;
    padding-bottom: 0
  }

  .md1-py {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .md2-py {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .md3-py {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .md4-py {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .md5-py {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .md6-py {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .md7-py {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .md8-py {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .md9-py {
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  .md10-py {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .md11-py {
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  .md12-py {
    padding-top: 12rem;
    padding-bottom: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-py {
    padding-top:0;
    padding-bottom: 0
  }

  .lg1-py {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .lg2-py {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg3-py {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .lg4-py {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .lg5-py {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .lg6-py {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .lg7-py {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .lg8-py {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .lg9-py {
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  .lg10-py {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .lg11-py {
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  .lg12-py {
    padding-top: 12rem;
    padding-bottom: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-px {
    padding-right:0;
    padding-left: 0
  }

  .sm1-px {
    padding-right: 1rem;
    padding-left: 1rem
  }

  .sm2-px {
    padding-right: 2rem;
    padding-left: 2rem
  }

  .sm3-px {
    padding-right: 3rem;
    padding-left: 3rem
  }

  .sm4-px {
    padding-right: 4rem;
    padding-left: 4rem
  }

  .sm5-px {
    padding-right: 5rem;
    padding-left: 5rem
  }

  .sm6-px {
    padding-right: 6rem;
    padding-left: 6rem
  }

  .sm7-px {
    padding-right: 7rem;
    padding-left: 7rem
  }

  .sm8-px {
    padding-right: 8rem;
    padding-left: 8rem
  }

  .sm9-px {
    padding-right: 9rem;
    padding-left: 9rem
  }

  .sm10-px {
    padding-right: 10rem;
    padding-left: 10rem
  }

  .sm11-px {
    padding-right: 11rem;
    padding-left: 11rem
  }

  .sm12-px {
    padding-right: 12rem;
    padding-left: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-px {
    padding-right:0;
    padding-left: 0
  }

  .md1-px {
    padding-right: 1rem;
    padding-left: 1rem
  }

  .md2-px {
    padding-right: 2rem;
    padding-left: 2rem
  }

  .md3-px {
    padding-right: 3rem;
    padding-left: 3rem
  }

  .md4-px {
    padding-right: 4rem;
    padding-left: 4rem
  }

  .md5-px {
    padding-right: 5rem;
    padding-left: 5rem
  }

  .md6-px {
    padding-right: 6rem;
    padding-left: 6rem
  }

  .md7-px {
    padding-right: 7rem;
    padding-left: 7rem
  }

  .md8-px {
    padding-right: 8rem;
    padding-left: 8rem
  }

  .md9-px {
    padding-right: 9rem;
    padding-left: 9rem
  }

  .md10-px {
    padding-right: 10rem;
    padding-left: 10rem
  }

  .md11-px {
    padding-right: 11rem;
    padding-left: 11rem
  }

  .md12-px {
    padding-right: 12rem;
    padding-left: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-px {
    padding-right:0;
    padding-left: 0
  }

  .lg1-px {
    padding-right: 1rem;
    padding-left: 1rem
  }

  .lg2-px {
    padding-right: 2rem;
    padding-left: 2rem
  }

  .lg3-px {
    padding-right: 3rem;
    padding-left: 3rem
  }

  .lg4-px {
    padding-right: 4rem;
    padding-left: 4rem
  }

  .lg5-px {
    padding-right: 5rem;
    padding-left: 5rem
  }

  .lg6-px {
    padding-right: 6rem;
    padding-left: 6rem
  }

  .lg7-px {
    padding-right: 7rem;
    padding-left: 7rem
  }

  .lg8-px {
    padding-right: 8rem;
    padding-left: 8rem
  }

  .lg9-px {
    padding-right: 9rem;
    padding-left: 9rem
  }

  .lg10-px {
    padding-right: 10rem;
    padding-left: 10rem
  }

  .lg11-px {
    padding-right: 11rem;
    padding-left: 11rem
  }

  .lg12-px {
    padding-right: 12rem;
    padding-left: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-pt {
    padding-top:0
  }

  .sm1-pt {
    padding-top: 1rem
  }

  .sm2-pt {
    padding-top: 2rem
  }

  .sm3-pt {
    padding-top: 3rem
  }

  .sm4-pt {
    padding-top: 4rem
  }

  .sm5-pt {
    padding-top: 5rem
  }

  .sm6-pt {
    padding-top: 6rem
  }

  .sm7-pt {
    padding-top: 7rem
  }

  .sm8-pt {
    padding-top: 8rem
  }

  .sm9-pt {
    padding-top: 9rem
  }

  .sm10-pt {
    padding-top: 10rem
  }

  .sm11-pt {
    padding-top: 11rem
  }

  .sm12-pt {
    padding-top: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-pt {
    padding-top:0
  }

  .md1-pt {
    padding-top: 1rem
  }

  .md2-pt {
    padding-top: 2rem
  }

  .md3-pt {
    padding-top: 3rem
  }

  .md4-pt {
    padding-top: 4rem
  }

  .md5-pt {
    padding-top: 5rem
  }

  .md6-pt {
    padding-top: 6rem
  }

  .md7-pt {
    padding-top: 7rem
  }

  .md8-pt {
    padding-top: 8rem
  }

  .md9-pt {
    padding-top: 9rem
  }

  .md10-pt {
    padding-top: 10rem
  }

  .md11-pt {
    padding-top: 11rem
  }

  .md12-pt {
    padding-top: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-pt {
    padding-top:0
  }

  .lg1-pt {
    padding-top: 1rem
  }

  .lg2-pt {
    padding-top: 2rem
  }

  .lg3-pt {
    padding-top: 3rem
  }

  .lg4-pt {
    padding-top: 4rem
  }

  .lg5-pt {
    padding-top: 5rem
  }

  .lg6-pt {
    padding-top: 6rem
  }

  .lg7-pt {
    padding-top: 7rem
  }

  .lg8-pt {
    padding-top: 8rem
  }

  .lg9-pt {
    padding-top: 9rem
  }

  .lg10-pt {
    padding-top: 10rem
  }

  .lg11-pt {
    padding-top: 11rem
  }

  .lg12-pt {
    padding-top: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-pr {
    padding-right:0
  }

  .sm1-pr {
    padding-right: 1rem
  }

  .sm2-pr {
    padding-right: 2rem
  }

  .sm3-pr {
    padding-right: 3rem
  }

  .sm4-pr {
    padding-right: 4rem
  }

  .sm5-pr {
    padding-right: 5rem
  }

  .sm6-pr {
    padding-right: 6rem
  }

  .sm7-pr {
    padding-right: 7rem
  }

  .sm8-pr {
    padding-right: 8rem
  }

  .sm9-pr {
    padding-right: 9rem
  }

  .sm10-pr {
    padding-right: 10rem
  }

  .sm11-pr {
    padding-right: 11rem
  }

  .sm12-pr {
    padding-right: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-pr {
    padding-right:0
  }

  .md1-pr {
    padding-right: 1rem
  }

  .md2-pr {
    padding-right: 2rem
  }

  .md3-pr {
    padding-right: 3rem
  }

  .md4-pr {
    padding-right: 4rem
  }

  .md5-pr {
    padding-right: 5rem
  }

  .md6-pr {
    padding-right: 6rem
  }

  .md7-pr {
    padding-right: 7rem
  }

  .md8-pr {
    padding-right: 8rem
  }

  .md9-pr {
    padding-right: 9rem
  }

  .md10-pr {
    padding-right: 10rem
  }

  .md11-pr {
    padding-right: 11rem
  }

  .md12-pr {
    padding-right: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-pr {
    padding-right:0
  }

  .lg1-pr {
    padding-right: 1rem
  }

  .lg2-pr {
    padding-right: 2rem
  }

  .lg3-pr {
    padding-right: 3rem
  }

  .lg4-pr {
    padding-right: 4rem
  }

  .lg5-pr {
    padding-right: 5rem
  }

  .lg6-pr {
    padding-right: 6rem
  }

  .lg7-pr {
    padding-right: 7rem
  }

  .lg8-pr {
    padding-right: 8rem
  }

  .lg9-pr {
    padding-right: 9rem
  }

  .lg10-pr {
    padding-right: 10rem
  }

  .lg11-pr {
    padding-right: 11rem
  }

  .lg12-pr {
    padding-right: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-pb {
    padding-bottom:0
  }

  .sm1-pb {
    padding-bottom: 1rem
  }

  .sm2-pb {
    padding-bottom: 2rem
  }

  .sm3-pb {
    padding-bottom: 3rem
  }

  .sm4-pb {
    padding-bottom: 4rem
  }

  .sm5-pb {
    padding-bottom: 5rem
  }

  .sm6-pb {
    padding-bottom: 6rem
  }

  .sm7-pb {
    padding-bottom: 7rem
  }

  .sm8-pb {
    padding-bottom: 8rem
  }

  .sm9-pb {
    padding-bottom: 9rem
  }

  .sm10-pb {
    padding-bottom: 10rem
  }

  .sm11-pb {
    padding-bottom: 11rem
  }

  .sm12-pb {
    padding-bottom: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-pb {
    padding-bottom:0
  }

  .md1-pb {
    padding-bottom: 1rem
  }

  .md2-pb {
    padding-bottom: 2rem
  }

  .md3-pb {
    padding-bottom: 3rem
  }

  .md4-pb {
    padding-bottom: 4rem
  }

  .md5-pb {
    padding-bottom: 5rem
  }

  .md6-pb {
    padding-bottom: 6rem
  }

  .md7-pb {
    padding-bottom: 7rem
  }

  .md8-pb {
    padding-bottom: 8rem
  }

  .md9-pb {
    padding-bottom: 9rem
  }

  .md10-pb {
    padding-bottom: 10rem
  }

  .md11-pb {
    padding-bottom: 11rem
  }

  .md12-pb {
    padding-bottom: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-pb {
    padding-bottom:0
  }

  .lg1-pb {
    padding-bottom: 1rem
  }

  .lg2-pb {
    padding-bottom: 2rem
  }

  .lg3-pb {
    padding-bottom: 3rem
  }

  .lg4-pb {
    padding-bottom: 4rem
  }

  .lg5-pb {
    padding-bottom: 5rem
  }

  .lg6-pb {
    padding-bottom: 6rem
  }

  .lg7-pb {
    padding-bottom: 7rem
  }

  .lg8-pb {
    padding-bottom: 8rem
  }

  .lg9-pb {
    padding-bottom: 9rem
  }

  .lg10-pb {
    padding-bottom: 10rem
  }

  .lg11-pb {
    padding-bottom: 11rem
  }

  .lg12-pb {
    padding-bottom: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-pl {
    padding-left:0
  }

  .sm1-pl {
    padding-left: 1rem
  }

  .sm2-pl {
    padding-left: 2rem
  }

  .sm3-pl {
    padding-left: 3rem
  }

  .sm4-pl {
    padding-left: 4rem
  }

  .sm5-pl {
    padding-left: 5rem
  }

  .sm6-pl {
    padding-left: 6rem
  }

  .sm7-pl {
    padding-left: 7rem
  }

  .sm8-pl {
    padding-left: 8rem
  }

  .sm9-pl {
    padding-left: 9rem
  }

  .sm10-pl {
    padding-left: 10rem
  }

  .sm11-pl {
    padding-left: 11rem
  }

  .sm12-pl {
    padding-left: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-pl {
    padding-left:0
  }

  .md1-pl {
    padding-left: 1rem
  }

  .md2-pl {
    padding-left: 2rem
  }

  .md3-pl {
    padding-left: 3rem
  }

  .md4-pl {
    padding-left: 4rem
  }

  .md5-pl {
    padding-left: 5rem
  }

  .md6-pl {
    padding-left: 6rem
  }

  .md7-pl {
    padding-left: 7rem
  }

  .md8-pl {
    padding-left: 8rem
  }

  .md9-pl {
    padding-left: 9rem
  }

  .md10-pl {
    padding-left: 10rem
  }

  .md11-pl {
    padding-left: 11rem
  }

  .md12-pl {
    padding-left: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-pl {
    padding-left:0
  }

  .lg1-pl {
    padding-left: 1rem
  }

  .lg2-pl {
    padding-left: 2rem
  }

  .lg3-pl {
    padding-left: 3rem
  }

  .lg4-pl {
    padding-left: 4rem
  }

  .lg5-pl {
    padding-left: 5rem
  }

  .lg6-pl {
    padding-left: 6rem
  }

  .lg7-pl {
    padding-left: 7rem
  }

  .lg8-pl {
    padding-left: 8rem
  }

  .lg9-pl {
    padding-left: 9rem
  }

  .lg10-pl {
    padding-left: 10rem
  }

  .lg11-pl {
    padding-left: 11rem
  }

  .lg12-pl {
    padding-left: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-m {
    margin:0
  }

  .sm1-m {
    margin: 1rem
  }

  .sm2-m {
    margin: 2rem
  }

  .sm3-m {
    margin: 3rem
  }

  .sm4-m {
    margin: 4rem
  }

  .sm5-m {
    margin: 5rem
  }

  .sm6-m {
    margin: 6rem
  }

  .sm7-m {
    margin: 7rem
  }

  .sm8-m {
    margin: 8rem
  }

  .sm9-m {
    margin: 9rem
  }

  .sm10-m {
    margin: 10rem
  }

  .sm11-m {
    margin: 11rem
  }

  .sm12-m {
    margin: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-m {
    margin:0
  }

  .md1-m {
    margin: 1rem
  }

  .md2-m {
    margin: 2rem
  }

  .md3-m {
    margin: 3rem
  }

  .md4-m {
    margin: 4rem
  }

  .md5-m {
    margin: 5rem
  }

  .md6-m {
    margin: 6rem
  }

  .md7-m {
    margin: 7rem
  }

  .md8-m {
    margin: 8rem
  }

  .md9-m {
    margin: 9rem
  }

  .md10-m {
    margin: 10rem
  }

  .md11-m {
    margin: 11rem
  }

  .md12-m {
    margin: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-m {
    margin:0
  }

  .lg1-m {
    margin: 1rem
  }

  .lg2-m {
    margin: 2rem
  }

  .lg3-m {
    margin: 3rem
  }

  .lg4-m {
    margin: 4rem
  }

  .lg5-m {
    margin: 5rem
  }

  .lg6-m {
    margin: 6rem
  }

  .lg7-m {
    margin: 7rem
  }

  .lg8-m {
    margin: 8rem
  }

  .lg9-m {
    margin: 9rem
  }

  .lg10-m {
    margin: 10rem
  }

  .lg11-m {
    margin: 11rem
  }

  .lg12-m {
    margin: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-my {
    margin-top:0;
    margin-bottom: 0
  }

  .sm1-my {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .sm2-my {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .sm3-my {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .sm4-my {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .sm5-my {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .sm6-my {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .sm7-my {
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  .sm8-my {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .sm9-my {
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  .sm10-my {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .sm11-my {
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  .sm12-my {
    margin-top: 12rem;
    margin-bottom: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-my {
    margin-top:0;
    margin-bottom: 0
  }

  .md1-my {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .md2-my {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .md3-my {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .md4-my {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .md5-my {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .md6-my {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .md7-my {
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  .md8-my {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .md9-my {
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  .md10-my {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .md11-my {
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  .md12-my {
    margin-top: 12rem;
    margin-bottom: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-my {
    margin-top:0;
    margin-bottom: 0
  }

  .lg1-my {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .lg2-my {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .lg3-my {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .lg4-my {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .lg5-my {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .lg6-my {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .lg7-my {
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  .lg8-my {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .lg9-my {
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  .lg10-my {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .lg11-my {
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  .lg12-my {
    margin-top: 12rem;
    margin-bottom: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-mx {
    margin-right:0;
    margin-left: 0
  }

  .sm1-mx {
    margin-right: 1rem;
    margin-left: 1rem
  }

  .sm2-mx {
    margin-right: 2rem;
    margin-left: 2rem
  }

  .sm3-mx {
    margin-right: 3rem;
    margin-left: 3rem
  }

  .sm4-mx {
    margin-right: 4rem;
    margin-left: 4rem
  }

  .sm5-mx {
    margin-right: 5rem;
    margin-left: 5rem
  }

  .sm6-mx {
    margin-right: 6rem;
    margin-left: 6rem
  }

  .sm7-mx {
    margin-right: 7rem;
    margin-left: 7rem
  }

  .sm8-mx {
    margin-right: 8rem;
    margin-left: 8rem
  }

  .sm9-mx {
    margin-right: 9rem;
    margin-left: 9rem
  }

  .sm10-mx {
    margin-right: 10rem;
    margin-left: 10rem
  }

  .sm11-mx {
    margin-right: 11rem;
    margin-left: 11rem
  }

  .sm12-mx {
    margin-right: 12rem;
    margin-left: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-mx {
    margin-right:0;
    margin-left: 0
  }

  .md1-mx {
    margin-right: 1rem;
    margin-left: 1rem
  }

  .md2-mx {
    margin-right: 2rem;
    margin-left: 2rem
  }

  .md3-mx {
    margin-right: 3rem;
    margin-left: 3rem
  }

  .md4-mx {
    margin-right: 4rem;
    margin-left: 4rem
  }

  .md5-mx {
    margin-right: 5rem;
    margin-left: 5rem
  }

  .md6-mx {
    margin-right: 6rem;
    margin-left: 6rem
  }

  .md7-mx {
    margin-right: 7rem;
    margin-left: 7rem
  }

  .md8-mx {
    margin-right: 8rem;
    margin-left: 8rem
  }

  .md9-mx {
    margin-right: 9rem;
    margin-left: 9rem
  }

  .md10-mx {
    margin-right: 10rem;
    margin-left: 10rem
  }

  .md11-mx {
    margin-right: 11rem;
    margin-left: 11rem
  }

  .md12-mx {
    margin-right: 12rem;
    margin-left: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-mx {
    margin-right:0;
    margin-left: 0
  }

  .lg1-mx {
    margin-right: 1rem;
    margin-left: 1rem
  }

  .lg2-mx {
    margin-right: 2rem;
    margin-left: 2rem
  }

  .lg3-mx {
    margin-right: 3rem;
    margin-left: 3rem
  }

  .lg4-mx {
    margin-right: 4rem;
    margin-left: 4rem
  }

  .lg5-mx {
    margin-right: 5rem;
    margin-left: 5rem
  }

  .lg6-mx {
    margin-right: 6rem;
    margin-left: 6rem
  }

  .lg7-mx {
    margin-right: 7rem;
    margin-left: 7rem
  }

  .lg8-mx {
    margin-right: 8rem;
    margin-left: 8rem
  }

  .lg9-mx {
    margin-right: 9rem;
    margin-left: 9rem
  }

  .lg10-mx {
    margin-right: 10rem;
    margin-left: 10rem
  }

  .lg11-mx {
    margin-right: 11rem;
    margin-left: 11rem
  }

  .lg12-mx {
    margin-right: 12rem;
    margin-left: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-mt {
    margin-top:0
  }

  .sm1-mt {
    margin-top: 1rem
  }

  .sm2-mt {
    margin-top: 2rem
  }

  .sm3-mt {
    margin-top: 3rem
  }

  .sm4-mt {
    margin-top: 4rem
  }

  .sm5-mt {
    margin-top: 5rem
  }

  .sm6-mt {
    margin-top: 6rem
  }

  .sm7-mt {
    margin-top: 7rem
  }

  .sm8-mt {
    margin-top: 8rem
  }

  .sm9-mt {
    margin-top: 9rem
  }

  .sm10-mt {
    margin-top: 10rem
  }

  .sm11-mt {
    margin-top: 11rem
  }

  .sm12-mt {
    margin-top: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-mt {
    margin-top:0
  }

  .md1-mt {
    margin-top: 1rem
  }

  .md2-mt {
    margin-top: 2rem
  }

  .md3-mt {
    margin-top: 3rem
  }

  .md4-mt {
    margin-top: 4rem
  }

  .md5-mt {
    margin-top: 5rem
  }

  .md6-mt {
    margin-top: 6rem
  }

  .md7-mt {
    margin-top: 7rem
  }

  .md8-mt {
    margin-top: 8rem
  }

  .md9-mt {
    margin-top: 9rem
  }

  .md10-mt {
    margin-top: 10rem
  }

  .md11-mt {
    margin-top: 11rem
  }

  .md12-mt {
    margin-top: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-mt {
    margin-top:0
  }

  .lg1-mt {
    margin-top: 1rem
  }

  .lg2-mt {
    margin-top: 2rem
  }

  .lg3-mt {
    margin-top: 3rem
  }

  .lg4-mt {
    margin-top: 4rem
  }

  .lg5-mt {
    margin-top: 5rem
  }

  .lg6-mt {
    margin-top: 6rem
  }

  .lg7-mt {
    margin-top: 7rem
  }

  .lg8-mt {
    margin-top: 8rem
  }

  .lg9-mt {
    margin-top: 9rem
  }

  .lg10-mt {
    margin-top: 10rem
  }

  .lg11-mt {
    margin-top: 11rem
  }

  .lg12-mt {
    margin-top: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-mr {
    margin-right:0
  }

  .sm1-mr {
    margin-right: 1rem
  }

  .sm2-mr {
    margin-right: 2rem
  }

  .sm3-mr {
    margin-right: 3rem
  }

  .sm4-mr {
    margin-right: 4rem
  }

  .sm5-mr {
    margin-right: 5rem
  }

  .sm6-mr {
    margin-right: 6rem
  }

  .sm7-mr {
    margin-right: 7rem
  }

  .sm8-mr {
    margin-right: 8rem
  }

  .sm9-mr {
    margin-right: 9rem
  }

  .sm10-mr {
    margin-right: 10rem
  }

  .sm11-mr {
    margin-right: 11rem
  }

  .sm12-mr {
    margin-right: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-mr {
    margin-right:0
  }

  .md1-mr {
    margin-right: 1rem
  }

  .md2-mr {
    margin-right: 2rem
  }

  .md3-mr {
    margin-right: 3rem
  }

  .md4-mr {
    margin-right: 4rem
  }

  .md5-mr {
    margin-right: 5rem
  }

  .md6-mr {
    margin-right: 6rem
  }

  .md7-mr {
    margin-right: 7rem
  }

  .md8-mr {
    margin-right: 8rem
  }

  .md9-mr {
    margin-right: 9rem
  }

  .md10-mr {
    margin-right: 10rem
  }

  .md11-mr {
    margin-right: 11rem
  }

  .md12-mr {
    margin-right: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-mr {
    margin-right:0
  }

  .lg1-mr {
    margin-right: 1rem
  }

  .lg2-mr {
    margin-right: 2rem
  }

  .lg3-mr {
    margin-right: 3rem
  }

  .lg4-mr {
    margin-right: 4rem
  }

  .lg5-mr {
    margin-right: 5rem
  }

  .lg6-mr {
    margin-right: 6rem
  }

  .lg7-mr {
    margin-right: 7rem
  }

  .lg8-mr {
    margin-right: 8rem
  }

  .lg9-mr {
    margin-right: 9rem
  }

  .lg10-mr {
    margin-right: 10rem
  }

  .lg11-mr {
    margin-right: 11rem
  }

  .lg12-mr {
    margin-right: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-mb {
    margin-bottom:0
  }

  .sm1-mb {
    margin-bottom: 1rem
  }

  .sm2-mb {
    margin-bottom: 2rem
  }

  .sm3-mb {
    margin-bottom: 3rem
  }

  .sm4-mb {
    margin-bottom: 4rem
  }

  .sm5-mb {
    margin-bottom: 5rem
  }

  .sm6-mb {
    margin-bottom: 6rem
  }

  .sm7-mb {
    margin-bottom: 7rem
  }

  .sm8-mb {
    margin-bottom: 8rem
  }

  .sm9-mb {
    margin-bottom: 9rem
  }

  .sm10-mb {
    margin-bottom: 10rem
  }

  .sm11-mb {
    margin-bottom: 11rem
  }

  .sm12-mb {
    margin-bottom: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-mb {
    margin-bottom:0
  }

  .md1-mb {
    margin-bottom: 1rem
  }

  .md2-mb {
    margin-bottom: 2rem
  }

  .md3-mb {
    margin-bottom: 3rem
  }

  .md4-mb {
    margin-bottom: 4rem
  }

  .md5-mb {
    margin-bottom: 5rem
  }

  .md6-mb {
    margin-bottom: 6rem
  }

  .md7-mb {
    margin-bottom: 7rem
  }

  .md8-mb {
    margin-bottom: 8rem
  }

  .md9-mb {
    margin-bottom: 9rem
  }

  .md10-mb {
    margin-bottom: 10rem
  }

  .md11-mb {
    margin-bottom: 11rem
  }

  .md12-mb {
    margin-bottom: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-mb {
    margin-bottom:0
  }

  .lg1-mb {
    margin-bottom: 1rem
  }

  .lg2-mb {
    margin-bottom: 2rem
  }

  .lg3-mb {
    margin-bottom: 3rem
  }

  .lg4-mb {
    margin-bottom: 4rem
  }

  .lg5-mb {
    margin-bottom: 5rem
  }

  .lg6-mb {
    margin-bottom: 6rem
  }

  .lg7-mb {
    margin-bottom: 7rem
  }

  .lg8-mb {
    margin-bottom: 8rem
  }

  .lg9-mb {
    margin-bottom: 9rem
  }

  .lg10-mb {
    margin-bottom: 10rem
  }

  .lg11-mb {
    margin-bottom: 11rem
  }

  .lg12-mb {
    margin-bottom: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0-ml {
    margin-left:0
  }

  .sm1-ml {
    margin-left: 1rem
  }

  .sm2-ml {
    margin-left: 2rem
  }

  .sm3-ml {
    margin-left: 3rem
  }

  .sm4-ml {
    margin-left: 4rem
  }

  .sm5-ml {
    margin-left: 5rem
  }

  .sm6-ml {
    margin-left: 6rem
  }

  .sm7-ml {
    margin-left: 7rem
  }

  .sm8-ml {
    margin-left: 8rem
  }

  .sm9-ml {
    margin-left: 9rem
  }

  .sm10-ml {
    margin-left: 10rem
  }

  .sm11-ml {
    margin-left: 11rem
  }

  .sm12-ml {
    margin-left: 12rem
  }
}

@media only screen and (min-width: 601px) {
  .md0-ml {
    margin-left:0
  }

  .md1-ml {
    margin-left: 1rem
  }

  .md2-ml {
    margin-left: 2rem
  }

  .md3-ml {
    margin-left: 3rem
  }

  .md4-ml {
    margin-left: 4rem
  }

  .md5-ml {
    margin-left: 5rem
  }

  .md6-ml {
    margin-left: 6rem
  }

  .md7-ml {
    margin-left: 7rem
  }

  .md8-ml {
    margin-left: 8rem
  }

  .md9-ml {
    margin-left: 9rem
  }

  .md10-ml {
    margin-left: 10rem
  }

  .md11-ml {
    margin-left: 11rem
  }

  .md12-ml {
    margin-left: 12rem
  }
}

@media only screen and (min-width: 993px) {
  .lg0-ml {
    margin-left:0
  }

  .lg1-ml {
    margin-left: 1rem
  }

  .lg2-ml {
    margin-left: 2rem
  }

  .lg3-ml {
    margin-left: 3rem
  }

  .lg4-ml {
    margin-left: 4rem
  }

  .lg5-ml {
    margin-left: 5rem
  }

  .lg6-ml {
    margin-left: 6rem
  }

  .lg7-ml {
    margin-left: 7rem
  }

  .lg8-ml {
    margin-left: 8rem
  }

  .lg9-ml {
    margin-left: 9rem
  }

  .lg10-ml {
    margin-left: 10rem
  }

  .lg11-ml {
    margin-left: 11rem
  }

  .lg12-ml {
    margin-left: 12rem
  }
}

@media only screen and (min-width: 0) {
  .sm0 {
    width:0%;
    max-width: 0%
  }

  .sm1 {
    width: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sm2 {
    width: 16.66666666667%;
    max-width: 16.66666666667%
  }

  .sm3 {
    width: 25%;
    max-width: 25%
  }

  .sm4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sm5 {
    width: 41.66666666667%;
    max-width: 41.66666666667%
  }

  .sm6 {
    width: 50%;
    max-width: 50%
  }

  .sm7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sm8 {
    width: 66.66666666667%;
    max-width: 66.66666666667%
  }

  .sm9 {
    width: 75%;
    max-width: 75%
  }

  .sm10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sm11 {
    width: 91.66666666667%;
    max-width: 91.66666666667%
  }

  .sm12 {
    width: 100%;
    max-width: 100%
  }
}

@media only screen and (min-width: 601px) {
  .md0 {
    width:0%;
    max-width: 0%
  }

  .md1 {
    width: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .md2 {
    width: 16.66666666667%;
    max-width: 16.66666666667%
  }

  .md3 {
    width: 25%;
    max-width: 25%
  }

  .md4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .md5 {
    width: 41.66666666667%;
    max-width: 41.66666666667%
  }

  .md6 {
    width: 50%;
    max-width: 50%
  }

  .md7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .md8 {
    width: 66.66666666667%;
    max-width: 66.66666666667%
  }

  .md9 {
    width: 75%;
    max-width: 75%
  }

  .md10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .md11 {
    width: 91.66666666667%;
    max-width: 91.66666666667%
  }

  .md12 {
    width: 100%;
    max-width: 100%
  }
}

@media only screen and (min-width: 993px) {
  .lg0 {
    width:0%;
    max-width: 0%
  }

  .lg1 {
    width: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .lg2 {
    width: 16.66666666667%;
    max-width: 16.66666666667%
  }

  .lg3 {
    width: 25%;
    max-width: 25%
  }

  .lg4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .lg5 {
    width: 41.66666666667%;
    max-width: 41.66666666667%
  }

  .lg6 {
    width: 50%;
    max-width: 50%
  }

  .lg7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .lg8 {
    width: 66.66666666667%;
    max-width: 66.66666666667%
  }

  .lg9 {
    width: 75%;
    max-width: 75%
  }

  .lg10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .lg11 {
    width: 91.66666666667%;
    max-width: 91.66666666667%
  }

  .lg12 {
    width: 100%;
    max-width: 100%
  }
}


body.page-template-template-events-archive{
  .block-title-text{
    p{
      font-size: 2rem;
    }
  }
  .section.portfolio-intro{

  }
}

.block-title-text{
  text-align: center;
  padding: 6rem 0;

  h1, .h1{

    padding: 0 0 0.8em 0;
    text-align: center;

    &:after{
      content: '';
      display: block;
      height: 0.2rem;
      width: 6rem;
      margin: 0 auto;
      margin-top: 2rem;
      background-color: #9deeca;
      background: #97b1ec;
      background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
      background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
      background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    }
  }

  a:not([class]){
    text-decoration: underline;
  }

  &.header-line-before{
    h1, .h1{
      &:before{
        content: '';
        display: block;
        height: 0.2rem;
        width: 6rem;
        margin: 4rem auto 4rem;
        background-color: #9deeca;
        background: #97b1ec;
        background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
        background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
        background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
      }
    }
  }

}

.block-title-text-left{
  h1, h2{
    text-align: center;
  }

  ul{
    list-style: initial;
    padding: 0 0 40px 40px;
    margin: initial;

    li{
      list-style: initial;
    }
  }
}
.block-title-text-wide{
  h1, h2{
    text-align: left;
  }

  ul{
    list-style: initial;
    padding: 0 0 40px 40px;
    margin: initial;

    li{
      list-style: initial;
    }
  }
}


@media only screen and (max-width: $media-sm) {
  section.simple-text-button{
    .col.sm-middle{
      padding: 3rem 0 0;

      .buttons{
        margin-bottom: 0;
      }
    }
  }
  .block-title-text {
    h1, .h1{
      //padding-bottom: 0;
      margin-bottom: 0;

      &:after{
        display: none;
      }
    }

    &.header-line-before{
      h1, .h1{
        &:before{
          display: none;
        }
      }
    }
  }
}

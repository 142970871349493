.sidebar{
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  width: 250px;
  margin-right: -250px;
  background-color: #fff;
  transition: all 0.5s;
  padding: 1rem;
  overflow: auto;
  z-index: 999;

  &__close{
    position: absolute;
    top: 2rem;
    right: 2rem;
    height: 20px;
    width: 20px;
    background-color: #fff;
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
  }
}

.side-nav{
  .arrow-more{
    cursor: pointer;
    &:before{
      display: inline-block;
      font-family: FontAwesome;
      content: "\f067";
      width: 15px;
      margin-right: 1rem;
      text-align: center;
      font-size: 12px;
    }
    &.active:before{
      content: "\f068";
    }
  }
  ul.menu{
    & > li:first-child a{
      font-size: 0;
      padding-bottom: 50%;
      background-image: url("../img/menu-logo.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    a{
      display: inline-block;
    }
  }
}

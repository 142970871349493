body {
}
/*//////////////////////////////////////////////////////////////////////////////////////////////////////////

    _main2.css additions to original main.css

    Version: 1.0.0
    CreatedOn: 18/05/17
    CreatedBy: lukas@webnetism.com
    UpdatedOn: 18/05/17

//////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.d-flex{
  display: flex;
}
.h-100{
  height: 100%;
}
@media only screen and (min-width: 993px) {
  .containerServiceBoxout {
    display: flex;
  }

  .containerServiceBoxout {
    margin-top: 60px;
  }

  .containerServiceBoxout .col.sm12 {
    height: 100%;
  }

  .containerServiceBoxout .serviceBoxout {
    height: 100%;
    max-height: none;
    margin-top: 0;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/*// Small and Up */
@media only screen and (min-width: 0px) {
  .breadcrumb { display:none; }
  .col { outline:none; }
  .featured-awards-partners { padding: 8rem 0; min-height:30rem;}
  .featured-awards-partners img{ margin:auto;display:block;    padding: 0 2rem; }
  .featured-awards-partners .slick-awards{ height: 30rem;}


  ol.ol-big-numb {
    padding-left: 0;
    list-style: none;
    color: #ccc;
  }

  ol.ol-big-numb li {
    /*font: bold 24pt helvetica, arial, sans-serif;*/
    position: relative;
    margin-bottom: 20px;
  }

  .ol-big-numb li p {
    /*font: 12pt helvetica, arial, sans-serif;*/
    color: #555;
    padding-left: 30px;
  }

  .ol-big-numb span {
    position: absolute;
    /*line-height: 25px;*/
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .banner { width:100%; }
  .banner img { width:100%; display:block; margin:0 auto;}
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {

  /*bars*/
  .sm-bar {
    display:block;
    height:1rem;
    width:100%;
    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .sm-top-bar:before {}
  .sm-right-bar {
    position:relative;
  }
  .sm-right-bar:after {
    content:"";
    display:block;
    height:100%;
    width:0.3rem;
    margin:0 auto;
    position:absolute;
    top:0;
    right:0;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .sm-bottom-bar:after {
    content:"";
    display:block;
    height:0.3rem;
    width:6rem;
    margin:0 auto;
    margin-top: 2rem;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .sm-bottom-left-bar:after {
    content:"";
    display:block;
    height:0.3rem;
    width:6rem;
    margin-top: 2rem;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }

  .sm-left-bar {
    position:relative;
  }
  .sm-left-bar:before {
    content:"";
    display:block;
    height:100%;
    width:0.3rem;
    margin:0 auto;
    position:absolute;
    top:0;
    left:0;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }

  .sm-bottom-bar-hover {}
  /*eof:bars*/

  /*enlarge*/
  .sm4-enlarge { font-size: 4rem; line-height:100%; }
  /*eof:enlarge*/

  /*btn*/
  .sm-btn-primary,
  .sm-btn-primary:link  {
    background-color:#101010;
    text-transform:uppercase;
    display:inline-block;
    margin:0;
    min-width:210px;
    font-family: 'Neutraface 2 Display Bold';
    font-size:1.4rem;
    padding:1rem;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;

  }
  .sm-btn-primary-outline,
  .sm-btn-primary-outline:link {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #222427;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 30px;
    line-height: 100%;
    font-size:1.4rem;
    width: auto;
    max-width: none;
    color: #222427;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .sm-btn-primary:hover,.sm-btn-primary:link:hover {
    box-shadow:none;
    color:#9deeca;
    text-decoration: none;
  }
  .sm-btn-secondary,
  .sm-btn-secondary:link {
    text-transform:uppercase;
    color:#212721;
    background-color: transparent;
    border-bottom:2px solid #9deeca;
    border-color:#9deeca;
    font-family: 'Neutraface 2 Display Bold';
    font-size:1.4rem;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;

  }
  .sm-btn-secondary:hover,.sm-btn-secondary:link:hover {
    box-shadow:none;
    text-decoration: none;
  }
  .sm-btn-primary:after,
  .sm-btn-primary:link:after,
  .sm-btn-primary-outline:after,
  .sm-btn-primary-outline:link:after{
    font-family:"FontAwesome";
    content:"\f178";
    margin-left:1em;
    font-size:80%;
  }
  .sm-btn-primary.down:after,
  .sm-btn-primary.down:link:after,
  .sm-btn-primary-outline.down:after,
  .sm-btn-primary-outline.down:link:after {
    font-family: "FontAwesome";
    content: "\f175";
    margin-left: 1em;
    font-size: 80%;
    display: block;
    text-align: center;
  }
  .sm-btn-secondary:hover, .sm-btn-secondary:link:hover {
    box-shadow: none;
    text-decoration: none;
  }
  /*eof:btn*/
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  /*bars*/
  .md-bar {
    display:block;
    height:1rem;
    width:100%;
    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .md-top-bar:before {}
  .md-right-bar {
    position:relative;
  }
  .md-right-bar:after {
    content:"";
    display:block;
    height:100%;
    width:0.2rem;
    margin:0 auto;
    position:absolute;
    top:0;
    right:0;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .md-bottom-bar:after {
    content:"";
    display:block;
    height:0.2rem;
    width:9rem;
    margin:0 auto;
    margin-top: 2rem;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .md-bottom-left-bar:after {
    content:"";
    display:block;
    height:0.2rem;
    width:9rem;
    margin-top: 2rem;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }

  .md-left-bar {
    position:relative;
  }
  .md-left-bar:before {
    content:"";
    display:block;
    height:100%;
    width:0.2rem;
    margin:0 auto;
    position:absolute;
    top:0;
    left:0;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }

  .md-bottom-bar-hover {}
  /*eof:bars*/
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  /*bars*/
  .lg-bar {
    display:block;
    height:1rem;
    width:100%;
    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .lg-top-bar:before {}
  .lg-right-bar {
    position:relative;
  }
  .lg-right-bar:after {
    content:"";
    display:block;
    height:100%;
    width:0.2rem;
    margin:0 auto;
    position:absolute;
    top:0;
    right:0;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .lg-bottom-bar:after {
    content:"";
    display:block;
    height:0.2rem;
    width:9rem;
    margin:0 auto;
    margin-top: 2rem;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .lg-bottom-left-bar:after {
    content:"";
    display:block;
    height:0.2rem;
    width:9rem;
    margin-top: 2rem;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }

  .lg-left-bar {
    position:relative;
  }
  .lg-left-bar:before {
    content:"";
    display:block;
    height:100%;
    width:0.2rem;
    margin:0 auto;
    position:absolute;
    top:0;
    left:0;

    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }

  .lg-bottom-bar-hover {}
  /*eof:bars*/
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .container {
    padding:0 1rem;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .container {
    padding:0;
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .sm-text-left {text-align:left;}
  .sm-text-center {text-align:center;}
  .sm-text-right {text-align:right;}
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .md-text-left {text-align:left;}
  .md-text-center {text-align:center;}
  .md-text-right {text-align:right;}
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .lg-text-left {text-align:left;}
  .lg-text-center {text-align:center;}
  .lg-text-right {text-align:right;}
}


@media only screen and (max-width: 600px) {
  .sm-right-bar:after{
    display: none;
  }
}

section.clients-list{
  .value-info{
    color: $color-primary;
    font-family: $font-bold;
  }

  .grey-logos{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: $media-sm) {
  section.clients-list{
    display: none;
  }
}

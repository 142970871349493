section.featured-team{
  padding: 6rem 0;
}

.featured-team-banner {
  width: 100% !important;

  .slick-list {
    width: 100%;
  }

}

@media only screen and (max-width: $media-sm) {
  .featured-team-banner{
    margin: 0;
  }
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////

    _typography.css

    Version: 1.0.0
    CreatedOn: 01/05/17
    CreatedBy: lukas@webnetism.com
    UpdatedOn: 01/05/17

//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

@media print {
  img {
    display: none !important; } }

@font-face {
  font-family: 'Neutraface 2 Text Light';
  src: url("../fonts/Neutraface2Text-Light.eot");
  src: url("../fonts/Neutraface2Text-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Neutraface2Text-Light.woff") format("woff"), url("../fonts/Neutraface2Text-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Neutraface 2 Display Medium';
  src: url("../fonts/Neutraface2Display-Medium.eot");
  src: url("../fonts/Neutraface2Display-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Neutraface2Display-Medium.woff") format("woff"), url("../fonts/Neutraface2Display-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Neutraface 2 Display Bold';
  src: url("../fonts/Neutraface2Display-Bold.eot");
  src: url("../fonts/Neutraface2Display-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Neutraface2Display-Bold.woff") format("woff"), url("../fonts/Neutraface2Display-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Neutraface 2 Text Bold';
  src: url("../fonts/Neutraface2Text-Bold.eot");
  src: url("../fonts/Neutraface2Text-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Neutraface2Text-Bold.woff") format("woff"), url("../fonts/Neutraface2Text-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Neutraface 2 Text Book';
  src: url("../fonts/Neutraface2Text-Book.eot");
  src: url("../fonts/Neutraface2Text-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/Neutraface2Text-Book.woff") format("woff"), url("../fonts/Neutraface2Text-Book.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Neutraface 2 Display Light';
  src: url("../fonts/Neutraface2Display-Light.eot");
  src: url("../fonts/Neutraface2Display-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Neutraface2Display-Light.woff") format("woff"), url("../fonts/Neutraface2Display-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Neutraface 2 Text Demi';
  src: url("../fonts/Neutraface2Text-Demi.eot");
  src: url("../fonts/Neutraface2Text-Demi.eot?#iefix") format("embedded-opentype"), url("../fonts/Neutraface2Text-Demi.woff") format("woff"), url("../fonts/Neutraface2Text-Demi.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal; }


html {
  font-size:62.5%;
  font-family:'Neutraface 2 Text light', sans-serif;
  color:#222427;
}
strong {
  font-family: 'Neutraface 2 Text Bold';
}


/*// Small and Up */

@media only screen and (min-width: 0px) {
  .terms { text-transform:initial!important;font-size: 14px;}
  .terms a{ color:white;text-decoration:underline;}

  .terms.terms-black a{ color:#000;text-decoration:underline;}
  .black{ color:#000;}
  body {
    font-size:1.8rem;
    line-height:140%;
    text-align:center;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight:normal;
    padding:0 0 0.8em 0;
    margin:0;
    line-height:1.2em;
    font-family: 'Neutraface 2 Text Demi';
  }

  h1, .h1 {
    font-family: 'Neutraface 2 Text Light';
    font-size:3.5rem;
  }
  h2, .h2 {
    font-family: 'Neutraface 2 Text Light';
    font-size:2.5rem;

  }
  h2.decorative:after {
    content:url('../img/header-bar-ring.png');
    padding-left:2rem;
  }
  h2 a {
    font-family: 'Neutraface 2 Text Light';

  }
  h3, .h3 {
    font-size:2.4rem;
  }
  h4, .h4 {
    text-transform:uppercase;
    font-size:1.4rem;
  }
  h5 {}
  h6 {}

  p {
    padding:0 0 1em 0;
    margin:0;
  }
  blockquote  {}

  ul, ol {
    margin-top: 0;
    margin-bottom: 0;
    text-align:left;
  }
  ul li, ol li {
  }
  ul ul, ol ul, ul ol, ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }

  table {
    border-spacing: 0px;
    border-collapse: collapse;
  }
  table td,
  table th {
    padding: 0;
  }

  a {
    font-family:'Neutraface 2 Text Bold';
    color:#a5d8c2;
    text-decoration:none;
  }
  a:hover {
    text-decoration:underline;
  }
  h1 > a, h2 > a, h3 a, h4 a, h5 a, h6 a {
    text-decoration:none;
  }
  .nolink {
    /*font-family:'Neutraface 2 Text Book';*/
    color:#222427;
  }
}
.intro {
  font-size:150%;
  line-height:120%;
}

.textcenter {
  text-align:center;
}
.textright {
  text-align:right;
}
.highlight {
  color:#a5d8c2;
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  body {
    text-align: left;
  }
  h1, .h1 {
    font-family: 'Neutraface 2 Text Light';
    font-size: 5rem;
  }
  h2, .h2 {
    font-family: 'Neutraface 2 Text Light';
    font-size: 3.5rem;
  }
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

@media only screen and (min-width: 0px) {
  .results {
    background-color:#222427;
    color:#fff;
    position:relative;
    padding-bottom:6rem;
  }
  .results:before  {
    content:"";
    display:block;
    height:0.75rem;
    width:100%;
    background-color:#9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .results .container {
    padding-top:8rem;
  }
  .results .container::before {
    background-image:url('../img/IN-icon.png');
    margin-top:-10.5rem;
    display:inline-block;
    background-size: 4rem 4rem;
    width:4rem;
    height:4rem;
    left:calc(50% - 2rem);
    position:absolute;
    content:"";
  }
  .results ul {
    list-style:none;
  }
  .results ul li{
    text-align:center;
    padding:0.5rem;
  }
  .results ul li strong {
    display:block;
  }

}
/*// Medium and Up */
@media only screen and (min-width: 601px) {
  .results ul li strong {
    display:inline-block;
  }
}
/*// Large and Up */
@media only screen and (min-width: 993px) {
}




section.section-contact{
  background-image: url("../img/footer.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;


  .c-button{
    border: 2px solid #fff !important;
    padding: 7px 40px;
    color: #fff !important;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    font-size: 80%;

    &:hover{
      text-decoration: none;
      color: #222427!important;
      background-color: #fff;
    }
  }

}

.contact{

  &__info{

    &__content{
      padding-top: 8rem;
      padding-bottom: 4rem;
    }

    &__bar{
      background: rgba(34, 47, 54, 0.9);
      padding: 15px 20px;
      color: #fff;
      margin-top: 5rem;
      margin-bottom: 2rem;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1170px;
      position: relative;

      p{
        margin: 0;

        a{
          font-weight: bold;
        }
      }

      .btn{
        padding: 7px 40px;
      }

      &__image{
        position: absolute;
        bottom: 3rem;
        left: 5rem;
      }

      &__text{
        padding-left: 10rem;
      }
    }
  }
}

@media only screen and (max-width: $media-sm) {
  .contact{
    &__info{
      &__bar{
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__text{
          padding-left: 0;
          text-align: center;
        }
        &__image{
          display: none;
        }
      }
    }
  }
}

section.full-width-box{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  width: 100%;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3em 0;

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 8px;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }

  .btn{
    padding: 1.2rem 3rem;
    min-width: 210px;
  }
}


@media only screen and (max-width: $media-sm) {
  section.full-width-box{
   body.home & {
     display: none;
   }
    .buttons{
      justify-content: center;
    }
  }
}

.header_solution{
  &.video{
    position: relative;

    h2, h1{
      display: none;
    }

    video{
      width: 100%;
      height: 56vw;
    }
  }


}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////

    _contact.css

    Version: 1.0.0
    CreatedOn: 30/05/17
    CreatedBy: lukas@webnetism.com
    UpdatedOn: 30/05/17

//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .contact-form {
    outline:none;
    border:0;
  }
  .contact-form:hover {
    border:none;
  }
  .contact-form .item {
    background-color:#141414;
  }
  .contact-form .item h2 {
    font-family: 'Neutraface 2 Text Bold';
    font-size:25px;
    color:#fff;
    text-transform:uppercase;
    white-space:normal;
    margin-bottom: 0;
  }
  .contact-form .item label {
    font-family: 'Neutraface 2 Text Book';
    font-size:14px;
    color:#fff;
    text-transform:uppercase;
    display:block;    white-space: normal;

  }
  .contact-form .item input {
    display:block;
    width:100%;
    height:36px;
    line-height:36px;
    padding:0 2rem;
  }
  .contact-form .item textarea {
    display:block;
    width:100%;
    height:108px;
    line-height:36px;
    padding:0 2rem;
  }
  .contact-form .item .sm-btn-primary-outline {
    color:#fff;
    border-color:#fff;
  }
  .contact-form .item.item2 {
    background-image:url("/wp-content/uploads/contact-infinitynation-bg.jpg");
    background-position:center;
    background-size:cover;
    padding:50% 0;
    text-align:center;
  }
  .contact-form .item.item2 span {
    display:none;
  }
  .contact-form .item .txtValidation {
    display:block;
    color:#cc0000;
    margin-top:-27px;
    margin-right:1rem;
    float:right;
    pointer-events:none;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .contact-form .item {
  }
  .contact-form .item.item2 {
    min-height:613px;
    max-height:613px;
    min-height: 700px;
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .contact-form:after {
    content:"";
    display:block;
    height:400px;
    width:100%;
    background-color:#f4f4f2;
    margin-top:-400px;
    border-top:1px solid #a7acaf;
    pointer-events:none;
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .contact-maps {
    background-color:#f4f4f2;
    .sm-btn-primary-outline:hover{
      text-decoration: none;
    }
  }
  .contact-maps .item .map-iframe{
    padding-bottom: 50%;
    display: block;
    position: relative;
  }
  .contact-maps .item .map {
    width:100%;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    max-width:100%;
    border:0;
    margin:0 auto;
    display:block;
  }
  .contact-maps .item h2 {
    text-transform:uppercase;
    font-family: 'Neutraface 2 Text Demi';
    font-size:18px;
    line-height:100%;
    color:#141414;
    margin-top:2rem;
  }
  .contact-maps .item h3 {
    text-transform:uppercase;
    font-family: 'Neutraface 2 Text Demi';
    font-size:18px;
    line-height:100%;
    color:#141414;
    margin-top:4rem;
  }
  .contact-maps .item a {
    font-family: 'Neutraface 2 Text Demi';
    color:#131313;
  }
  .contact-maps .item a.sm-btn-primary-outline {
    font-size:12px;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .contact-maps .item iframe {
    height:460px;
  }

  .contact-maps .item h3 {
    margin-top:2rem;
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

@media only screen and (max-width: $media-sm) {
  .section.contact-form.sm4-mt {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .section.contact-form.sm4-mt{
    iframe{
      height: 750px !important;
    }
  }
}

@media only screen and (max-width: 350px) {
  .section.contact-form.sm4-mt{
    iframe{
      height: 805px !important;
    }
  }
}


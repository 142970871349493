.favourite-things li:nth-child(1):before {
  background-image: url('../img/team-enjoys-icon-1.png');
}

.favourite-things li:nth-child(2):before {
  background-image: url('../img/team-enjoys-icon-2.png');
}

.favourite-things li:nth-child(3):before {
  background-image: url('../img/team-enjoys-icon-3.png');
}

.favourite-things li:nth-child(4):before {
  background-image: url('../img/team-enjoys-icon-4.png');
}
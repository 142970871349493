/*//////////////////////////////////////////////////////////////////////////////////////////////////////////

    _portfolio.css

    Version: 1.0.0
    CreatedOn: 18/05/17
    CreatedBy: lukas@webnetism.com
    UpdatedOn: 18/05/17

//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .lucky-banner .slick-list {
    overflow: visible;
  }

  .pc-team .lucky-banner .slick-list, .pc-solutions .lucky-banner .slick-list {
    overflow: hidden;
  }

  .lucky-banner {
    /*margin-bottom:6rem;*/
  }

  .lucky-banner:before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    border-right: 0 solid #fff;
    border-left: 0 solid #fff;
    pointer-events: none;
  }

  .lucky-banner:after {
    content: "";
    display: block;
    height: 1rem;
    width: 100%;
    background-color: #9deeca;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  }
  .pc-home .lucky-banner:after {
    content: "";
    display: none;
  }

  .lucky-banner p {
    font-size:2.2rem;
    line-height:130%;

    a{
      display: inline-block;
      text-decoration: none !important;
    }
  }

  .lucky-banner-item {
    height: auto;
    outline: none;
    text-align: center;
    position: relative;
    z-index: 1;
  }

  .lucky-banner-item img {
    display: block;
    width: 100%;
  }

  .lucky-banner-item-content {
    /*display:inline-block;*/
    text-align: center;
    position: relative;
    z-index: 2;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .lucky-banner-item-content {
    text-align: left;
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Homepage banner*/
  .lucky-banner-item-homepage .container {
    padding: 0;
  }

  .lucky-banner-item-homepage {
    background-image: none;
  }

  .lucky-banner-item-homepage:after {
    display: block;
    content: url('/wp-content/uploads/home-banner/banner-homepage-geometric.png');
    position: absolute;
    bottom: -57px;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index:10;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content {
    background-image: url('/wp-content/uploads/home-banner/home-banner-inner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 9rem 2rem;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content h1 {
    /*font-size: 5rem;
    line-height: 120%;*/
    text-transform: uppercase;
    font-family: 'Neutraface 2 Text Light';
  }

  .lucky-banner-item-content h1 strong {
    font-family: 'Neutraface 2 Text Demi';
  }

  .lucky-banner-item-homepage .lucky-banner-item-content h1 small {
    display: block;
    font-size: 35%;
    line-height: 120%;
    margin-bottom: 2rem;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content ul {
    display: none;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-primary {
    background-color: rgba(255, 255, 255, 0.75);
    border: 2px solid #222427;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 30px;
    line-height: 100%;
    width: auto;
    max-width: none;
    color: #222427;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-secondary {
    display: none;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content .sm-bottom-left-bar:after {
    display: none;
  }
  /*eof:homepage banner*/
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .lucky-banner-item-homepage {
    background-image: none;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content {
    margin: 0;
    padding: 12rem 8rem;
    max-height: 650px;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content ul {
    display: none;
    list-style: none;
    list-style-type: none;
    margin: 2rem 0 4rem 0;
    padding: 0;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content ul li {
    display: inline-block;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content ul li:after {
    content: '';
    display: inline-block;
    height: 2px;
    width: 20px;
    background: #97b1ec;
    background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
    background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
    margin: 4px 8px;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content ul li:nth-last-child():after {
    display: none;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content ul li a {
    color: #222427;
    text-transform: uppercase;
    font-size: 1.6rem;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-primary {
    margin-right: 2rem;
    background-color: rgba(255, 255, 255, 1);
  }

  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-secondary {
    display: inline-block;
    background-color: rgba(255, 255, 255, 1);
    border: 2px solid #fff;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 30px;
    line-height: 100%;
    width: auto;
    max-width: none;
    color: #222427;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-primary:hover,
  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-secondary:hover {
    background-color: #222427;
    color: #fff;
    border-color: #222427;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-secondary:after,
  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-secondary:link:after {
    font-family: "FontAwesome";
    content: "\f178";
    margin-left: 1em;
    font-size: 80%;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content .sm-bottom-left-bar:after {
    height: 0.1rem;
    width: 12rem;
    display: block;
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .lucky-banner-item-homepage {
    background-image: url('/wp-content/uploads/home-banner/home-banner-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content {
    margin: 12rem auto;
    padding: 8rem;
    height: 650px;
    max-height: 650px;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content p {
    max-width: 80%;
  }

  .lucky-banner-item-homepage .lucky-banner-item-content ul {
    display: block;
  }
  .lucky-banner-item-homepage .lucky-banner-item-content .sm-btn-primary {
    background-color: rgba(255, 255, 255, 0);
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .lucky-banner-item-blog {
    background-image: url('/wp-content/uploads/banners/banner-blog.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .lucky-banner-item-blog:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: " ";
    background-image: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.7) 30% );
    z-index: 0;
  }

  .lucky-banner-item-blog > .container > .row {
    min-height: 400px;
  }

  .lucky-banner-item-blog .lucky-banner-item-content {
    margin: 4rem 0.5rem;
    padding: 3rem 1rem;
  }

  .lucky-banner-item-blog .lucky-banner-item-content h1 {
    font-size: 25px;
    color: #fff;
  }

  .lucky-banner-item-blog .lucky-banner-item-content p {
    font-size: 18px;
    color: #fff;
  }

  .lucky-banner-item-blog .lucky-banner-item-content .release-date {
    font-size: 40px;
    color: #fff;
  }

  .lucky-banner-item-blog .lucky-banner-item-content .release-date {
    display: block;
    text-align: center;
  }

  .lucky-banner-item-blog .lucky-banner-item-content .release-date span {
    line-height: 100%;
    display: block;
  }

  .lucky-banner-item-blog .lucky-banner-item-content .release-date span.day {
  }

  .lucky-banner-item-blog .lucky-banner-item-content .release-date span.month {
  }

  .lucky-banner-item-blog .lucky-banner-item-content .release-date span.year {
    display: none;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .lucky-banner-item-blog > .container > .row {
    min-height: 600px;
  }

  .lucky-banner-item-blog:before {
    background-image: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.7) 60% );
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  .lucky-banner-item-blog:before {
    background-image: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.7) 50% );
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .lucky-banner-item-portfolio {
    background-image: url('/wp-content/uploads/banners/banner-portfolio.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  /*.lucky-banner-item-portfolio:after {
  content:"";
  display:block;
  height:1rem;
  width:100%;
  background-color:#9deeca;
  background: #97b1ec;
  background: -moz-linear-gradient(left, #97b1ec 0%, #9acfdf 51%, #92ecc4 100%);
  background: -webkit-linear-gradient(left, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
  background: linear-gradient(to right, #97b1ec 0%,#9acfdf 51%,#92ecc4 100%);
}*/
  .lucky-banner-item-portfolio .lucky-banner-item-content {
    background-image: url('/wp-content/uploads/banners/banner-portfolio-inner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 800px;
    margin: 8rem 0.5rem;
    padding: 3rem 1rem;
  }

  .lucky-banner-item-portfolio .lucky-banner-item-content h1 {
    color: #fff;
    text-transform: uppercase;
  }

  .lucky-banner-item-portfolio .lucky-banner-item-content h1 strong {
    display: block;
  }

  .lucky-banner-item-portfolio .lucky-banner-item-content p {
    color: #fff;
  }

  .lucky-banner-item-portfolio .lucky-banner-item-content .sm-btn-secondary {
    background-color: transparent;
    color: #fff;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .lucky-banner-item-portfolio .lucky-banner-item-content {
    margin: 8rem 2rem;
    padding: 5rem 10rem;
  }
}

/*// Large and Up */

@media only screen and (min-width: 993px) {
  .lucky-banner-item-portfolio .lucky-banner-item-content {
    margin: 10rem 4rem;
    padding: 10rem 10rem;
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/* insights */

/*// Small and Up */

@media only screen and (min-width: 0px) {

  section.insights-list{
    padding: 0;
  }

  .lucky-banner-item-insights {
    background-image: url('/wp-content/uploads/banners/banner-insights.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .lucky-banner-item-insights:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: " ";
    background-image: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.7) 30% );
    z-index: 0;
  }

  .lucky-banner-item-insights .lucky-banner-item-content {
    background-image: url('/wp-content/uploads/banners/banner-insights-inner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /*max-width:800px;*/
    margin: 8rem 0.5rem;
    padding: 3rem 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .lucky-banner-item-insights .lucky-banner-item-content:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: " ";
    background-image: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8) 30% );
    z-index: 0;
  }

  .lucky-banner-item-insights .lucky-banner-item-content h1 {
    color: #fff;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    /*font-family: 'Neutraface 2 Text Light';*/
  }

  .lucky-banner-item-insights .lucky-banner-item-content h1 strong {
    display: block;
  }

  .lucky-banner-item-insights .lucky-banner-item-content p {
    color: #fff;
    position: relative;
    z-index: 2;
  }

  .lucky-banner-item-insights .lucky-banner-item-content .sm-btn-secondary {
    background-color: transparent;
    color: #fff;
  }
}
/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .lucky-banner-item-insights:before {
    background-image: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8) 60% );
  }

  .lucky-banner-item-insights .lucky-banner-item-content {
    margin: 8rem 2rem;
    padding: 5rem 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  .lucky-banner-item-insights .lucky-banner-item-content:before {
    background-image: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8) 60% );
  }
}

/*// Large and Up */

@media only screen and (min-width: 993px) {
  .lucky-banner-item-insights:before {
    background-image: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1) 90% );
  }

  .lucky-banner-item-insights .lucky-banner-item-content {
    margin: 16rem 4rem;
    padding: 8rem 4rem;
    margin-left: auto;
    margin-right: auto;
  }

  .lucky-banner-item-insights .lucky-banner-item-content:before {
    background-image: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8) 80% );
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
}





/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Solutions banner*/
  .lucky-banner-item-solutions .container {
    padding: 0;
  }

  .lucky-banner-item-solutions {
    background-image: none;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content {
    background-image: url('/wp-content/uploads/home-banner/home-banner-inner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 9rem 2rem;
    color: #fff;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content h1 {
    /*font-size: 5rem;
    line-height: 120%;*/
    text-transform: uppercase;
    font-family: 'Neutraface 2 Text Light';
    color: #fff;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content h1 small {
    display: block;
    font-size: 35%;
    line-height: 120%;
    margin-bottom: 2rem;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content ul {
    display: none;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content .sm-btn {
    color: #fff;
    background: none;
    border-bottom: 2px solid #fff;
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content .sm-btn:hover {
    color: #fff;
    background: none;
    box-shadow: none;
    border-bottom: 2px solid #9deeca;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content .sm-bottom-left-bar:after {
    display: none;
  }
  /*eof:homepage banner*/
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .lucky-banner-item-solutions {
    background-image: none;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content {
    margin: 0;
    padding: 12rem 8rem;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content .sm-bottom-left-bar:after {
    height: 0.1rem;
    width: 12rem;
    display: block;
  }
}
/*// Large and Up */

@media only screen and (min-width: 993px) {
  .lucky-banner-item-solutions {
    background-image: url('/wp-content/uploads/home-banner/home-banner-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content {
    margin: 12rem auto;
    padding: 8rem;
    height: 650px;
    max-height: 650px;
  }

  .lucky-banner-item-solutions .lucky-banner-item-content p {
    max-width: 80%;
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Team banner*/
  .lucky-banner-item-team .container {
    padding: 0;
    color:#fff;
  }

  .lucky-banner-item-team {
    background-size: 0;
  }

  .lucky-banner-item-team .lucky-banner-item-content {
    background-image: url('/wp-content/uploads/home-banner/home-banner-inner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 9rem 2rem;

    a{
      text-transform: uppercase;
      background-color: transparent;
      color: #fff;
      border-bottom: 2px solid #9deeca;
      font-family: $font-bold;
      font-size: 1.4rem;
      -moz-transition: all 0.8s;
      -webkit-transition: all 0.8s;
      transition: all 0.8s;
      &:hover{
        text-decoration: none;
      }
    }
  }

  .lucky-banner-item-team .lucky-banner-item-content h1 {
    /*font-size: 5rem;
    line-height: 120%;*/
    text-transform: uppercase;
    font-family: 'Neutraface 2 Text Light';
  }

  .lucky-banner-item-team .lucky-banner-item-content h1 small {
    display: block;
    font-size: 35%;
    line-height: 120%;
    margin-bottom: 2rem;
  }

  .lucky-banner-item-team .lucky-banner-item-content ul {
    display: none;
  }

  .lucky-banner-item-team .lucky-banner-item-content .sm-btn {
    color: #fff;
    background: none;
    border-bottom: 2px solid #fff;
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
  }

  .lucky-banner-item-team .lucky-banner-item-content .sm-btn:hover {
    color: #fff;
    background: none;
    box-shadow: none;
    border-bottom: 2px solid #9deeca;
  }

  .lucky-banner-item-team .lucky-banner-item-content .sm-bottom-left-bar:after {
    display: none;
  }
  /*eof:homepage banner*/
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .lucky-banner-item-team {
    background-image: none;
  }

  .lucky-banner-item-team .lucky-banner-item-content {
    margin: 0;
    padding: 12rem 8rem;
    color: #fff;
  }

  .lucky-banner-item-team .lucky-banner-item-content .sm-bottom-left-bar:after {
    height: 0.1rem;
    width: 12rem;
    display: block;
  }
}
/*// Large and Up */

@media only screen and (min-width: 993px) {

  .lucky-banner-item-team {
    background-image: url('/wp-content/uploads/home-banner/home-banner-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .lucky-banner-item-team .lucky-banner-item-content {
    margin: 12rem auto;
    padding: 8rem;
    height: 650px;
    max-height: 650px;
  }

  .lucky-banner-item-team .lucky-banner-item-content p {
    max-width: 80%;
  }
}




/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  /*Team banner*/
  .lucky-banner-item-consultancy .container {
    padding: 0;
  }

  .lucky-banner-item-consultancy {
    background-image: none;
    background-size: cover;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content {
    background-image: url('/wp-content/uploads/home-banner/home-banner-inner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 9rem 2rem;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content h1 {
    /*font-size: 5rem;
    line-height: 120%;*/
    text-transform: uppercase;
    font-family: 'Neutraface 2 Text Light';
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content h1 small {
    display: block;
    font-size: 35%;
    line-height: 120%;
    margin-bottom: 2rem;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content ul {
    display: none;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content .sm-btn {
    color: #fff;
    background: none;
    border-bottom: 2px solid #fff;
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content .sm-btn:hover {
    color: #fff;
    background: none;
    box-shadow: none;
    border-bottom: 2px solid #9deeca;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content .sm-bottom-left-bar:after {
    display: none;
  }
  /*eof:homepage banner*/
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .lucky-banner-item-consultancy {
    background-image: none;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content {
    margin: 0;
    padding: 12rem 8rem;
    color: #222427;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content .sm-bottom-left-bar:after {
    height: 0.1rem;
    width: 12rem;
    display: block;
  }
}
/*// Large and Up */

@media only screen and (min-width: 993px) {
  .lucky-banner-item-consultancy {
    background-image: url('/wp-content/uploads/home-banner/home-banner-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content {
    margin: 12rem auto;
    padding: 8rem;
    height: 650px;
    max-height: 650px;
  }

  .lucky-banner-item-consultancy .lucky-banner-item-content p {
    max-width: 80%;
  }
}



/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {

  .lucky-banner-item-content .sm-btn-secondary {
    background-color: transparent;
    color: #fff;
    border-color: $color-primary;
  }
  .lucky-banner-item-consultancy .lucky-banner-item-content .sm-btn-secondary {
    background-color: transparent;
    color:#222427;
    border-color:#9deeca;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
}




/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  .serviceSection-banner.slick-initialized {
    width:100%;
    display:block;
    outline:none;
  }
  .featured-team-banner.slick-initialized {
    width:100%;
    display:block;
    outline:none;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

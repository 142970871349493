section.team-members{
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("../img/our-team.jpg");
  background-color: #151517;
  color: #fff;
  margin-top: 0;
  padding: 1rem;

  .btn{
    padding: 1.2rem 3rem;
    min-width: 210px;
  }

  &.team{
    .featured-team-banner.slick-initialized{
      margin-top:6rem;
    }
  }
}

.featured-team-banner {
  .slick-dots {
    margin-bottom: -1rem;
    li button:before {
      color: #fff;
    }
  }
}

@media only screen and (min-width: 0px) {
  .featured-team-banner.slick-initialized{
    margin-top: 2rem;
  }
  .featured-team .featured-team-banner .team-item {
    margin: 1rem;
  }
  .team, .team.our-team {
    margin-top:0;
    margin-bottom: 0;
    top: 0;
  }
  .team .team-item { position:relative; z-index:1; }
  .team .team-item .image {}
  .team .team-item .image a {}
  .team .team-item .image a img {
    display:block;
    max-width:100%;
    margin:0 auto;
  }
  .team .team-item .image {
    position:relative;
  }
  .team .team-item .image:after {
    content:"";
    display:block;
    background-color:rgba(23, 23, 24, 0.30);
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    pointer-events: none;
  }
  .team .team-item .copy {
    position:absolute;
    z-index:2;
    bottom:0;
    left:0;
    text-align:left;
  }
  .team .team-item .copy h2,
  .team .team-item .copy h2 a {
    margin:0; padding:0;
    font-family: 'Neutraface 2 Text Bold';
    font-size:18.5px;
    color:#fff;
  }
  .team .team-item .copy h3,
  .team .team-item .copy h3 a {
    margin:0; padding:0;
    font-family: 'Neutraface 2 Text Book';
    font-size:15.85px;
    color:#9deeca;
  }
  .team .team-item .copy h3:after {
    margin-top:1rem;
  }
}


/*// Medium and Up */

@media only screen and (min-width: 601px) {
  .team.our-team {
    top: -13rem;
    margin-bottom:-13rem;
    position: relative;
  }
}

/*// Large and Up */

@media only screen and (min-width: 993px) {
  .team.our-team {
    position: relative;
    top: -30rem;
    margin-bottom:-30rem;
  }
  .team .team-item .copy {
    display:none;
  }
  .team .team-item .image:after {
    display:none;
  }
  .team .team-item:hover .image:after {
    display:block;
  }
  .team .team-item:hover .copy {
    display:block;
  }
  .team .team-item .image {
    overflow:hidden;
  }
  .team .team-item .image a img {
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .team .team-item:hover .image a img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


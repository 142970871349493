/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*// Small and Up */

@media only screen and (min-width: 0px) {
  a.backTop {
    display:block;
    position:fixed;
    z-index:200;
    background-color:#000000;
    color:#fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding:5px;
    text-align:center;

    right:2rem;
    bottom:2rem;
  }
  a.backTop span {
    display:none;
  }
  a.backTop:after {
    display:inline-block;
    font-family:"Ionicons";
    content:"\f3d8";
    font-size:30px;
    line-height: 20px;
    position: relative;
    top: 3px;
    width:30px;
    height:28px;
    padding-top:2px;
  }
}

/*// Medium and Up */

@media only screen and (min-width: 601px) {
  a.backTop {
    right:3rem;
    bottom:3rem;
  }
}


/*// Large and Up */

@media only screen and (min-width: 993px) {
  a.backTop {
    transition: background 0.7s;
    -webkit-transition: background 0.7s;
    -moz-transition: background 0.7s;
    -ms-transition: background 0.7s;

    right:4rem;
    bottom:8rem;
  }
  a:hover.backTop {
    background-color:#a5d8c2;
  }
}


/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/

section.two-text-columns{
  background-color: white;
  padding-top: 12rem;
}


@media only screen and (max-width: $media-sm) {
  section.two-text-columns{
    padding-top: 6rem;
  }
}
